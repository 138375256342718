import React from "react";
import MainWrapper from "../Main/MainWrapper";
import { useState, useEffect } from "react";
import { useNavigate, useParams } from "react-router-dom";
import Form from "../Common/Form";
import { getCookie } from "../../utils";

const EditCity = () => {
  const navigate = useNavigate();
  const { id } = useParams();

  const loginedUserCookie = getCookie("loginData");
  let loginedUser = null;

  // Check if the cookie is available and parse it
  if (loginedUserCookie) {
    try {
      loginedUser = JSON.parse(loginedUserCookie);
    } catch (error) {
      console.error("Failed to parse login data:", error);
      navigate("/login"); // Redirect to login if parsing fails
    }
  } else {
    navigate("/login"); // Redirect to login if no cookie
  }

  const [states, setStates] = useState([]);

  const statesData = async () => {
    if (!loginedUser) return; // Avoid making requests if the user is not logged in
    try {
      const response = await fetch("https://api.menuverse.ai/states/show", {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          authorization: "Bearer " + loginedUser.data.token,
        },
      });

      if (response.status === 201 || response.status === 200) {
        const responseData = await response.json();
        setStates(responseData.data);
      } else if (response.status === 500) {
        const result = await response.json();
        window.toastr.error(result.message);
      } else if (response.status === 401 || response.status === 403) {
        navigate("/login");
      }
    } catch (error) {
      console.log("Error Occurred", error);
    }
  };

  useEffect(() => {
    statesData();
  }, []);

  const [formStructure, setFormStructure] = useState([]);

  useEffect(() => {
    if (!loginedUser) return; // Avoid making requests if the user is not logged in
    const formFields = [
      {
        fieldName: "state_id",
        fieldType: "select",
        fieldMask: "",
        fieldMaxLength: "",
        fieldRequired: "true",
        fieldDefaultValue: "",
        fieldValue: "",
        fieldOptions: states,
        dbType: "integer",
        fieldLabel: "State",
      },
      {
        fieldName: "name",
        fieldType: "text",
        fieldMask: "",
        fieldMaxLength: "100",
        fieldRequired: "true",
        fieldDefaultValue: "",
        fieldLabel: "City Name",
      },
    ];

    const getData = async () => {
      try {
        const response = await fetch(
          "https://api.menuverse.ai/cities/show/" + id,
          {
            method: "GET",
            headers: {
              "Content-Type": "application/json",
              authorization: "Bearer " + loginedUser.data.token,
            },
          }
        );

        if (response.status === 201 || response.status === 200) {
          const record = await response.json();
          const dealerData = record.data;
          setFormStructure(
            formFields.map((field) => {
              return {
                ...field,
                fieldDefaultValue: dealerData[field.fieldName],
              };
            })
          );
        } else if (response.status === 500) {
          const result = await response.json();
          window.toastr.error(result.message);
        } else if (response.status === 401 || response.status === 403) {
          navigate("/login");
        }
      } catch (error) {
        console.log("Error Occurred", error);
      }
    };

    getData();
  }, [states]);

  const formDefinition = {
    type: "edit",
    URL: "https://api.menuverse.ai/cities/update/" + id,
    successRedirect: "/cities",
  };

  return (
    <MainWrapper title="Edit City">
      <div className="card card-default" bis_skin_checked="1">
        <div className="card-header" bis_skin_checked="1">
          {/* <h2>Corporate</h2> */}
        </div>
        <div className="card-body" bis_skin_checked="1">
          <div
            className="row"
            bis_skin_checked="1"
            style={{
              border: "1px solid #eee",
              paddingTop: "20px",
              paddingBottom: "20px",
            }}
          >
            <div className="col-xl-12">
              <Form formFields={formStructure} {...formDefinition} />
            </div>
          </div>
        </div>
      </div>
    </MainWrapper>
  );
};

export default EditCity;
