import React from "react";
import Navbar from "./Navbar";
import Header from "./Header";
import Footer from "./Footer";

const MainWrapper = ({ title, children }) => {
  return (
    <>
      <Navbar />
      <div className="wrapper">
        <div className="page-wrapper">
          <Header title={title} />
          {children}
          <Footer />
        </div>
      </div>
    </>
  );
};

export default MainWrapper;
