import React from "react";

const CardMini = ({ header, agenda, value, progress }) => {
  return (
    <div className="card card-default card-mini">
      <div className="card-header">
        <h2>{header}</h2>
        <div className="dropdown">
          <a
            className="dropdown-toggle icon-burger-mini"
            href="#"
            role="button"
            id="dropdownMenuLink"
            data-toggle="dropdown"
            aria-haspopup="true"
            aria-expanded="false"
          ></a>

          <div
            className="dropdown-menu dropdown-menu-right"
            aria-labelledby="dropdownMenuLink"
          >
            <a className="dropdown-item" href="#">
              Action
            </a>
            <a className="dropdown-item" href="#">
              Another action
            </a>
            <a className="dropdown-item" href="#">
              Something else here
            </a>
          </div>
        </div>
        <div className="sub-title">
          <span className="mr-1">{agenda}</span> |
          <span className="mx-1">{value}</span>
          {progress == "up" ? (
            <i className="mdi mdi-arrow-up-bold text-success"></i>
          ) : (
            <i className="mdi mdi-arrow-down-bold text-danger"></i>
          )}
        </div>
      </div>
      <div className="card-body">
        <div className="chart-wrapper">
          <div>
            <div id="spline-area-1"></div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default CardMini;
