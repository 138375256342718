import React from "react";
import ContentWrapper from "./Internals/ContentWrapper";
import MainWrapper from "../Main/MainWrapper";

const Dashboard = () => {
  return (
    <>
      <MainWrapper>
        <ContentWrapper />
      </MainWrapper>
    </>
  );
};

export default Dashboard;
