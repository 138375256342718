import React, { useRef } from "react";
import { useState } from "react";
import { useNavigate } from "react-router-dom";
import { useEffect } from "react";
import Input from "../Common/Input";
import Select from "../Common/Select";
import Textarea from "../Common/Textarea";
import MultipleFormSections from "./MultipleFormSections";
import { getCookie } from "../../utils";
import parse from "html-react-parser";

const Form = ({
  formFields,
  type,
  URL,
  editRedirect,
  successRedirect,
  dbFormData,
  addFormData,
  dynamicValues,
  onSelectOption,
  showDefaultRadio,
  optionTitle,
  select_multiple,
}) => {
  const loginedUser = JSON.parse(getCookie("loginData"));
  const [clonedComponents, setClonedComponents] = useState([]);
  let prevFieldName = null;
  const navigate = useNavigate();

  let arrData = "";

  const inputRefs = useRef([]);
  const selectRefs = useRef([]);
  const textareaRefs = useRef([]);

  const [formValid, setFormValid] = useState(true);

  // useEffect to initialize formData when formFields changes
  useEffect(() => {
    console.log("formFields:", formFields);

    if (dbFormData) {
      console.log("dbFormData: ", dbFormData);
    }

    let newArr = [];

    const formDataInitialObject = formFields.reduce((acc, field) => {
      if (field.fieldType != "multiples") {
        acc[field.fieldName] = field.fieldDefaultValue
          ? field.fieldDefaultValue
          : field.fieldValue;
      } else {
        // console.log("got multiples");
        let editArr = [];
        if (dbFormData) {
          let newArr = [];
          editArr = dbFormData[field.fieldName];

          editArr.forEach((ar) => {
            arrData = field.multiples.reduce((options, multipleField) => {
              options[multipleField.fieldName] = ar[multipleField.fieldName];

              return options;
            }, {});

            newArr.push(arrData);
          });
          acc[field.fieldName] = newArr;
          // console.log("acccc", newArr);

          setClonedComponents(newArr);
        }
        if (addFormData) {
          acc[field.fieldName] = [];

          arrData = field.multiples.reduce((options, multipleField) => {
            options[multipleField.fieldName] = multipleField.fieldDefaultValue
              ? multipleField.fieldDefaultValue
              : multipleField.fieldValue;

            return options;
          }, {});
          acc[field.fieldName] = [];
          acc[field.fieldName].push(arrData);
          newArr.push(arrData);

          setClonedComponents(newArr);
        }
      }

      return acc;
    }, {});

    setFormData(formDataInitialObject);
  }, [formFields]);

  const handleShowDefaults = (e) => {
    // onSelectOption(e);
  };

  const [formData, setFormData] = useState({});

  // console.log(formData);
  // console.log("formData:", formData);

  const handleClone = (e) => {
    // alert("clicked");
    let newNode = JSON.parse(JSON.stringify(formData[e][0]));
    newNode.id = undefined;

    const newData = [...formData[e], newNode];
    console.log("newData", newData);
    console.log("formDatae", newNode);
    setFormData({ ...formData, [e]: newData });

    setClonedComponents([...clonedComponents, "component"]);
  };

  const handleChange = (e) => {
    // const key = Object.keys(e)[0].split("|");
    // const value = Object.values(e);
    // console.log(e);
    // formDataObj[key[0]][key[1]][key[2]] = value[0];
    // console.log("key", formDataObj);
    // console.log("Pre_formData", formDataObj[key[0]][key[1]][key[2]]);
    console.log("data", e);
    // setFormData(formDataObj);
    setFormData(e);
  };
  const handleValidations = (e) => {
    setFormValid(e);
  };
  const checkEmptyFields = () => {
    let errorCount = 0;
    for (const currentField of inputRefs.current) {
      if (currentField) {
        const currentFieldEl = currentField?.querySelector("input");
        const currentFormFieldValue =
          currentField?.querySelector("input").value;
        const currentFormFieldReqirement = currentField
          ?.querySelector("input")
          .getAttribute("data-isrequired");

        if (
          currentFormFieldValue.trim() == "" &&
          currentFormFieldReqirement == "true"
        ) {
          const errorEl = currentField?.querySelector("span");
          if (errorEl) {
            currentFieldEl.style.borderColor = "red";
            errorEl.textContent = "This field is required";
            errorEl.style.display = "block";
            errorCount++;
          }
        } else {
          const errorEl = currentField?.querySelector("span");
          if (errorEl) {
            currentFieldEl.style.borderColor = "#e5e9f2";
            errorEl.style.display = "none";
          }
        }
      }
      console.log("currentField", currentField?.querySelector("input").value);
      console.log("currentFieldnext", currentField?.querySelector("span"));
    }
    for (const currentField of selectRefs.current) {
      if (currentField) {
        const currentFieldEl = currentField?.querySelector("select");
        const currentFormFieldValue =
          currentField?.querySelector("select").value;
        const currentFormFieldReqirement = currentField
          ?.querySelector("select")
          .getAttribute("data-isrequired");
        if (
          currentFormFieldValue.trim() == "" &&
          currentFormFieldReqirement == "true"
        ) {
          const errorEl = currentField?.querySelector("span");
          if (errorEl) {
            currentFieldEl.style.borderColor = "red";
            errorEl.textContent = "This field is required";
            errorEl.style.display = "block";
            errorCount++;
          }
        } else {
          const errorEl = currentField?.querySelector("span");
          if (errorEl) {
            currentFieldEl.style.borderColor = "#e5e9f2";
            errorEl.style.display = "none";
          }
        }
      }
    }
    for (const currentField of textareaRefs.current) {
      if (currentField) {
        const currentFieldEl = currentField?.querySelector("textarea");
        const currentFormFieldValue =
          currentField?.querySelector("textarea").value;
        const currentFormFieldReqirement = currentField
          ?.querySelector("textarea")
          .getAttribute("data-isrequired");
        if (
          currentFormFieldValue.trim() == "" &&
          currentFormFieldReqirement == "true"
        ) {
          const errorEl = currentField?.querySelector("span");
          if (errorEl) {
            currentFieldEl.style.borderColor = "red";
            errorEl.textContent = "This field is required";
            errorEl.style.display = "block";
            errorCount++;
          }
        } else {
          const errorEl = currentField?.querySelector("span");
          if (errorEl) {
            currentFieldEl.style.borderColor = "#e5e9f2";
            errorEl.style.display = "none";
          }
        }
      }
    }

    if (errorCount == 0) {
      setFormValid(true);
      return true;
    } else {
      setFormValid(false);
      return false;
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    let formIsValid;

    if (formValid) {
      formIsValid = await checkEmptyFields();
    } else {
      formIsValid = false;
    }

    console.log("inputRefs", inputRefs);
    // return false;
    if (formIsValid) {
      console.log("BEFORE FORM DATA: ", formData);
      const data = formFields.reduce(
        (final, field) => {
          if (
            field.dbType &&
            field.dbType == "integer" &&
            field.select_multiple != "true"
          ) {
            formData[field.fieldName] = parseInt(formData[field.fieldName]);
          }
          if (field.select_multiple && field.select_multiple == "true") {
            formData[field.fieldName] = JSON.stringify(
              formData[field.fieldName]
            );
          }
          console.log("SUBMIT FORMDAAT REDUCe: ", formData[field.fieldName]);
          return (final = formData);
        },
        { formData }
      );

      try {
        const response = await fetch(URL, {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            authorization: "Bearer " + loginedUser.data.token,
          },
          body: JSON.stringify(data),
        });
        if (response.status === 201 || response.status === 200) {
          const result = await response.json();
          window.toastr.success(result.message);
          // if (successRedirect == "editform") {
          //   navigate(editRedirect + "/" + result.data.id);
          // } else {
          //   navigate(successRedirect);
          // }
        } else if (response.status === 500) {
          const result = await response.json();
          window.toastr.error(result.message);
        } else if (response.status === 401 || response.status === 403) {
          navigate("/login");
        }
      } catch (error) {
        console.error("Error submitting data:", error);
      }
    }
  };

  return (
    <form onSubmit={handleSubmit}>
      <div className="row">
        {formFields.map((column, index) => {
          let newInput = "";
          if (
            column.fieldType == "text" ||
            column.fieldType == "number" ||
            column.fieldType == "radio" ||
            column.fieldType == "password" ||
            column.fieldType == "date"
          ) {
            newInput =
              prevFieldName === column.fieldName ? (
                <div className="col-xl-12" bis_skin_checked="1">
                  <div
                    className="mb-5"
                    style={{ marginTop: "-25px" }}
                    bis_skin_checked="1"
                  >
                    <label
                      className="text-dark font-weight-medium"
                      for=""
                    ></label>
                    <div className="input-group mb-3" bis_skin_checked="1">
                      <Input
                        {...column}
                        formDataObj={formData}
                        onData={handleChange}
                        formValues={clonedComponents}
                        showDefaultRadio={showDefaultRadio}
                        validate={handleValidations}
                        ref={(el) => {
                          inputRefs.current[index] = el;
                        }}
                      />
                    </div>
                    <p style={{ fontSize: "90%" }}></p>
                  </div>
                </div>
              ) : (
                <>
                  {column.fieldType == "radio" ? (
                    <div
                      className="col-xl-12"
                      bis_skin_checked="1"
                      style={
                        column.fieldName == "id" ? { display: "none" } : {}
                      }
                    >
                      <div className="" bis_skin_checked="1">
                        <label className="text-dark font-weight-medium" for="">
                          {column.fieldLabel
                            ? parse(column.fieldLabel)
                            : column.fieldName.replace(/_/g, " ")}
                          {column.fieldRequired == "true" ? "*" : ""}
                        </label>
                        <div className="input-group" bis_skin_checked="1">
                          <Input
                            {...column}
                            formDataObj={formData}
                            onData={handleChange}
                            formValues={clonedComponents}
                            dynamicValues={dynamicValues}
                            showDefaultRadio={showDefaultRadio}
                            validate={handleValidations}
                            ref={(el) => {
                              inputRefs.current[index] = el;
                            }}
                          />
                        </div>
                        <p style={{ fontSize: "90%" }}></p>
                      </div>
                    </div>
                  ) : (
                    <div
                      className="col-xl-6"
                      bis_skin_checked="1"
                      style={
                        column.fieldName == "id" ? { display: "none" } : {}
                      }
                    >
                      <div className="mb-5" bis_skin_checked="1">
                        <label className="text-dark font-weight-medium" for="">
                          {column.fieldLabel
                            ? parse(column.fieldLabel)
                            : column.fieldName.replace(/_/g, " ")}
                          {column.fieldRequired == "true" ? "*" : ""}
                        </label>
                        <div className="input-group mb-3" bis_skin_checked="1">
                          <Input
                            {...column}
                            formDataObj={formData}
                            onData={handleChange}
                            formValues={clonedComponents}
                            dynamicValues={dynamicValues}
                            showDefaultRadio={showDefaultRadio}
                            validate={handleValidations}
                            ref={(el) => {
                              inputRefs.current[index] = el;
                            }}
                          />
                        </div>
                        <p style={{ fontSize: "90%" }}></p>
                      </div>
                    </div>
                  )}
                </>
              );

            prevFieldName = column.fieldName;
          } else if (column.fieldType == "checkbox") {
            newInput = (
              <div
                className="col-xl-12"
                bis_skin_checked="1"
                style={column.fieldName == "id" ? { display: "none" } : {}}
              >
                <div className="mb-5" bis_skin_checked="1">
                  <div className="input-group mb-3" bis_skin_checked="1">
                    <Input
                      {...column}
                      formDataObj={formData}
                      onData={handleChange}
                      formValues={clonedComponents}
                      dynamicValues={dynamicValues}
                      showDefaultRadio={showDefaultRadio}
                      validate={handleValidations}
                      ref={(el) => {
                        inputRefs.current[index] = el;
                      }}
                    />
                    <span style={{ marginLeft: "10px" }}>
                      {column.fieldLabel
                        ? parse(column.fieldLabel)
                        : column.fieldName.replace(/_/g, " ")}
                    </span>
                  </div>
                  <p style={{ fontSize: "90%" }}></p>
                </div>
              </div>
            );
          } else if (column.fieldType == "select") {
            newInput = (
              <div className="col-xl-6" bis_skin_checked="1">
                <div className="mb-5" bis_skin_checked="1">
                  <label className="text-dark font-weight-medium" for="">
                    {column.fieldLabel
                      ? parse(column.fieldLabel)
                      : column.fieldName.replace(/_/g, " ")}
                    {column.fieldRequired == "true" ? "*" : ""}
                  </label>
                  <div className="input-group mb-3" bis_skin_checked="1">
                    <Select
                      {...column}
                      onData={handleChange}
                      formDataObj={formData}
                      formValues={clonedComponents}
                      onSelectOption={handleShowDefaults}
                      ref={(el) => {
                        selectRefs.current[index] = el;
                      }}
                      validate={handleValidations}
                    />
                  </div>
                  <p style={{ fontSize: "90%" }}></p>
                </div>
              </div>
            );
          } else if (column.fieldType == "textarea") {
            newInput = (
              <div className="col-xl-6" bis_skin_checked="1">
                <div className="mb-5" bis_skin_checked="1">
                  <label className="text-dark font-weight-medium" for="">
                    {column.fieldLabel
                      ? parse(column.fieldLabel)
                      : column.fieldName.replace(/_/g, " ")}
                    {column.fieldRequired == "true" ? "*" : ""}
                  </label>
                  <div className="input-group mb-3" bis_skin_checked="1">
                    <Textarea
                      {...column}
                      formDataObj={formData}
                      onData={handleChange}
                      formValues={clonedComponents}
                      ref={(el) => {
                        textareaRefs.current[index] = el;
                      }}
                      validate={handleValidations}
                    />
                  </div>
                  <p style={{ fontSize: "90%" }}></p>
                </div>
              </div>
            );
          } else if (column.fieldType == "multiples") {
            // console.log("full components", clonedComponents);
            newInput = clonedComponents.map((compo, index) => {
              if (Object.keys(formData).length > 0) {
                // console.log("compooooo" + index, compo);
                return (
                  <MultipleFormSections
                    sectionIndex={index}
                    multipleName={column.fieldName}
                    multiples={column.multiples}
                    formDataObj={formData}
                    formValues={clonedComponents}
                    onMultipleData={handleChange}
                    onComponentClone={() => {
                      handleClone(column.fieldName);
                    }}
                    showDefaultRadio={showDefaultRadio}
                    optionTitle={optionTitle}
                    validate={handleValidations}
                  />
                );
              }
            });
          }

          return newInput;
        })}
      </div>
      <button type="submit" className="btn btn-primary">
        Submit
      </button>
    </form>
  );
};

export default Form;
