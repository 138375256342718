import React from "react";
import $, { get } from "jquery";
import "datatables.net";
import { Link, useNavigate } from "react-router-dom";
import { useEffect, useState } from "react";
import { getCookie } from "../../utils";

const Table = ({ apiData, excludedColumns }) => {
  const navigate = useNavigate();
  const loginedUser = JSON.parse(getCookie("loginData"));
  //data = table data
  const [data, setData] = useState([]);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await fetch(apiData.url, {
          method: apiData.method,
          headers: {
            "Content-Type": "application/json",
            authorization: "Bearer " + loginedUser.data.token,
          },
        });

        if (response.status === 201 || response.status === 200) {
        } else if (response.status === 500) {
          const result = await response.json();
          window.toastr.error(result.message);
        } else if (response.status === 401 || response.status === 403) {
          navigate("/login");
        }

        const responseData = await response.json();

        setData(responseData.data);
      } catch (error) {
        console.log("Error Occured", error);
      }
    };

    fetchData();
  }, []);

  useEffect(() => {
    if (data.length > 0) {
      $.fn.dataTable.ext.errMode = "none";
      $("#productsTable2").DataTable({
        info: false,
        lengthChange: false,
        lengthMenu: [
          [10, 20, 30, -1],
          [10, 20, 30, "All"],
        ],
        pageLength: 50,
        scrollX: true,
        order: [[2, "asc"]],

        language: {
          search: "_INPUT_",
          searchPlaceholder: "Search...",
        },
      });

      $("#productsTable2").css("marginTop", "-40px");
    }
  }, [data]);

  const deleteRecord = async (id) => {
    try {
      const response = await fetch(apiData.deleteUrl + "/" + id, {
        method: "delete",
        headers: {
          "Content-Type": "application/json",
          authorization: "Bearer " + loginedUser.data.token,
        },
      });

      if (response.status === 201 || response.status === 200) {
      } else if (response.status === 500) {
        const result = await response.json();
        window.toastr.error(result.message);
      } else if (response.status === 401 || response.status === 403) {
        navigate("/login");
      }

      const responseData = await response.json();

      return true;
    } catch (error) {
      console.log("Error Occured", error);
    }
  };

  const handleDelete = (id) => {
    if (window.confirm("Are you sure you want to delete this record?")) {
      deleteRecord(id);
      const updatedData = data.filter((record) => record.id !== id);
      setData(updatedData);
    }
  };
  return (
    <table
      id="productsTable2"
      className="table table-hover table-product"
      style={{ width: "100%" }}
    >
      <thead>
        <tr>
          <th>Actions</th>
          {data.length > 0 &&
            Object.keys(data[0]).map((column, key) => {
              if (excludedColumns.includes(column) === false) {
                return <th>{column.replace(/_/g, " ")}</th>;
              }
            })}
        </tr>
      </thead>
      <tbody>
        {data.length > 0 &&
          data.map((record) => {
            return (
              <tr key={record.id}>
                <td>
                  <Link to={`${apiData.editUrl}/${record.id}`}>Edit</Link>
                  <br />
                  <a href="#" onClick={() => handleDelete(record.id)}>
                    Delete
                  </a>
                </td>
                {Object.keys(data[0]).map((column) => {
                  if (excludedColumns.includes(column) === false) {
                    return <td>{record[column]}</td>;
                  }
                })}
              </tr>
            );
          })}
      </tbody>
    </table>
  );
};

export default Table;
