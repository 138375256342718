import React, { useState, useEffect } from "react";
import MainWrapper from "../Main/MainWrapper";
import { useNavigate, useParams } from "react-router-dom";
import Form from "../Common/Form";
import { getCookie } from "../../utils";
import axios from "axios";

const EditSaleperson = () => {
  const loginedUser = JSON.parse(getCookie("loginData"));
  const navigate = useNavigate();

  const [managers, setManagers] = useState([]);
  const [dealers, setDealers] = useState([]);
  // Fetch managers and dealers from API on component mount
  useEffect(() => {
    const fetchManagersAndDealers = async () => {
      try {
        const managerResponse = await axios.get(
          "https://api.menuverse.ai/managers/show",
          {
            headers: {
              "Content-Type": "application/json",
              authorization: `Bearer ${loginedUser.data.token}`,
            },
          }
        );

        const dealerResponse = await axios.get(
          "https://api.menuverse.ai/dealers/show",
          {
            headers: {
              "Content-Type": "application/json",
              authorization: `Bearer ${loginedUser.data.token}`,
            },
          }
        );

        setManagers(
          managerResponse.data.data.map((manager) => ({
            name: manager.name,
            id: manager.id,
          }))
        );
        setDealers(
          dealerResponse.data.data.map((dealer) => ({
            name: dealer.name,
            id: dealer.id,
          }))
        );
      } catch (error) {
        console.error("Failed to fetch managers or dealers:", error);
      }
    };

    fetchManagersAndDealers();
  }, [loginedUser.data.token]);
  const cities = [
    { name: "Amritsar", id: 1 },
    { name: "Jalandhar", id: 2 },
    { name: "Ludhiana", id: 3 },
  ];
  const states = [
    { name: "Delhi", id: 1 },
    { name: "Punjab", id: 2 },
    { name: "Haryana", id: 3 },
  ];

  const { id } = useParams();

  const formFields = [
    {
      fieldName: "firm_name",
      fieldType: "text",
      fieldMask: "",
      fieldMaxLength: "100",
      fieldRequired: "true",
      fieldDefaultValue: "",
      fieldLabel: "Name of the Firm?",
    },
    {
      fieldName: "dba",
      fieldType: "hidden",
      fieldMask: "",
      fieldMaxLength: "100",
      fieldDefaultValue: "",
    },
    {
      fieldName: "tax_id",
      fieldType: "hidden",
      fieldMask: "",
      fieldMaxLength: "100",
      fieldDefaultValue: "",
    },
    {
      fieldName: "zip",
      fieldType: "text",
      fieldMask: "",
      fieldMaxLength: "100",
      fieldRequired: "true",
      fieldDefaultValue: "",
      fieldLabel: "Zip Code",
    },
    {
      fieldName: "phone",
      fieldType: "text",
      fieldMask: "",
      fieldMaxLength: "100",
      fieldRequired: "true",
      fieldDefaultValue: "",
      fieldLabel: "Owner's Contact Number",
    },
    {
      fieldName: "fl_number",
      fieldType: "hidden",
      fieldMask: "",
      fieldMaxLength: "100",

      fieldDefaultValue: "",
    },
    {
      fieldName: "agreement_effective_date",
      fieldType: "date",
      fieldMask: "",
      fieldMaxLength: "100",
      fieldRequired: "true",
      fieldDefaultValue: "",
      fieldLabel: "Agreement Date",
    },
    {
      fieldName: "kick_off_date",
      fieldType: "date",
      fieldMask: "",
      fieldMaxLength: "100",
      fieldRequired: "true",
      fieldDefaultValue: "",
      fieldLabel: "Kick Off Date",
    },
    {
      fieldName: "dealerId",
      fieldType: "select",
      fieldOptions: dealers,
      fieldLabel: "Dealer",
    },
    {
      fieldName: "managerId",
      fieldType: "select",
      fieldOptions: managers,
      fieldLabel: "Manager",
    },
    ,
    // {
    //   fieldName: "will_dealer_mirror",
    //   fieldType: "radio",
    //   fieldMask: "",
    //   fieldMaxLength: "100",
    //   fieldRequired: "true",
    //   fieldDefaultValue: "",
    //   fieldValue: "yes",
    //   fieldLabel: "Will this Dealer mirror another Dealer's setup?",
    // },
    // {
    //   fieldName: "will_dealer_mirror",
    //   fieldType: "radio",
    //   fieldMask: "",
    //   fieldMaxLength: "100",
    //   fieldRequired: "true",
    //   fieldDefaultValue: "",
    //   fieldValue: "no",
    //   fieldLabel: "Will this Dealer mirror another Dealer's setup?",
    // },
    {
      fieldName: "city",
      fieldType: "select",
      fieldMask: "",
      fieldMaxLength: "",
      fieldRequired: "true",
      fieldDefaultValue: "",
      fieldValue: "",
      fieldOptions: cities,
      dbType: "integer",
      fieldLabel: "City",
    },
    {
      fieldName: "state",
      fieldType: "select",
      fieldMask: "",
      fieldMaxLength: "",
      fieldRequired: "true",
      fieldDefaultValue: "",
      fieldValue: "",
      fieldOptions: states,
      dbType: "integer",
      fieldLabel: "State",
    },
    {
      fieldName: "address",
      fieldType: "textarea",
      fieldMask: "",
      fieldMaxLength: "",
      fieldRequired: "true",
      fieldDefaultValue: "",
      fieldValue: "",
      fieldLabel: "Address",
    },
  ];

  const getData = async () => {
    let record = await fetch(
      "https://api.menuverse.ai/salespersons/show/" + id,
      {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          authorization: "Bearer " + loginedUser.data.token,
        },
      }
    );
    if (record.status === 201 || record.status === 200) {
    } else if (record.status === 500) {
      const result = await record.json();
      window.toastr.error(result.message);
    } else if (record.status === 401 || record.status === 403) {
      navigate("/login");
    }
    record = await record.json();
    const dealerData = record.data;

    setFormStructure(
      formFields.map((field) => {
        return { ...field, fieldDefaultValue: dealerData[field.fieldName] };
      })
    );
  };

  const [formStructure, setFormStructure] = useState(formFields);
  useEffect(() => {
    getData();
  }, []);

  const formDefinition = {
    type: "edit",
    URL: "https://api.menuverse.ai/managers/update/" + id,
    successRedirect: "/managers",
  };

  return (
    <>
      <MainWrapper title="Edit Sales Person">
        <div className="card card-default" bis_skin_checked="1">
          <div className="card-header" bis_skin_checked="1">
            <h2></h2>
          </div>
          <div className="card-body" bis_skin_checked="1">
            <div
              className="row"
              bis_skin_checked="1"
              style={{
                border: "1px solid #eee",
                paddingTop: "20px",
                paddingBottom: "20px",
              }}
            >
              <div className="col-xl-12">
                <Form formFields={formStructure} {...formDefinition} />
              </div>
            </div>
          </div>
        </div>
      </MainWrapper>
    </>
  );
};

export default EditSaleperson;
