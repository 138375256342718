import React from "react";
import MainWrapper from "../Main/MainWrapper";
import { useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { useEffect } from "react";
import Form from "../Common/Form";
import { getCookie } from "../../utils";

const EditDmsCodes = () => {
  const loginedUser = JSON.parse(getCookie("loginData"));
  const navigate = useNavigate();
  const { id } = useParams();

  const formFields = [
    {
      fieldName: "name",
      fieldType: "text",
      fieldMask: "",
      fieldMaxLength: "100",
      fieldRequired: "true",
      fieldDefaultValue: "",
      fieldLabel: "Name",
    },
  ];
  const getData = async () => {
    let record = await fetch("https://api.menverse.ai/dmscode/show/" + id, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        authorization: "Bearer " + loginedUser.data.token,
      },
    });

    if (record.status === 201 || record.status === 200) {
    } else if (record.status === 500) {
      const result = await record.json();
      window.toastr.error(result.message);
    } else if (record.status === 401 || record.status === 403) {
      navigate("/login");
    }
    record = await record.json();
    const dealerData = record.data;

    setFormStructure(
      formFields.map((field) => {
        return { ...field, fieldDefaultValue: dealerData[field.fieldName] };
      })
    );
  };

  const [formStructure, setFormStructure] = useState(formFields);
  useEffect(() => {
    getData();
  }, []);

  const formDefinition = {
    type: "edit",
    URL: "https://api.menverse.ai/dmscode/update/" + id,
    successRedirect: "/dmscodes",
  };

  return (
    <>
      <MainWrapper title="Edit DmsCodes">
        <div className="card card-default" bis_skin_checked="1">
          <div className="card-header" bis_skin_checked="1">
            {/* <h2></h2> */}
          </div>
          <div className="card-body" bis_skin_checked="1">
            <div
              className="row"
              bis_skin_checked="1"
              style={{
                border: "1px solid #eee",
                paddingTop: "20px",
                paddingBottom: "20px",
              }}
            >
              <div className="col-xl-12">
                <Form formFields={formStructure} {...formDefinition} />
              </div>
            </div>
          </div>
        </div>
      </MainWrapper>
    </>
  );
};

export default EditDmsCodes;
