import React from "react";

const Footer = () => {
  return (
    <footer className="footer mt-auto">
      <div className="copyright bg-white">
        <p>
          &copy; <span id="copy-year"></span> Copyright by {""}
          <a
            className="text-primary"
            href="http://54.183.100.74/"
            target="_blank"
          >
            MENUVERSE
          </a>
          .
        </p>
      </div>
      <script>
        var d = new Date(); var year = d.getFullYear();
        document.getElementById("copy-year").innerHTML = year;
      </script>
    </footer>
  );
};

export default Footer;
