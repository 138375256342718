import React, { useEffect, useState } from "react";
import MainWrapper from "../Main/MainWrapper";
import axios from "axios";
import { getCookie } from "../../utils";

const Customers = () => {
  const [customers, setCustomers] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const loginedUser = JSON.parse(getCookie("loginData"));

  useEffect(() => {
    const fetchCustomers = async () => {
      try {
        const loginedUser = JSON.parse(getCookie("loginData"));
        console.log("loginedUser", loginedUser.data.token); // Assuming the token is stored in cookies
        const response = await axios.get(
          "https://api.menuverse.ai/users/show",
          {
            headers: {
              Authorization: `Bearer ${loginedUser.data.token}`, // Pass the token in the Authorization header
            },
          }
        );

        setCustomers(response.data.data); // Update with response data
      } catch (err) {
        setError(err.message);
      } finally {
        setLoading(false);
      }
    };

    fetchCustomers();
  }, []);

  const handleConnect = (customerId, url, token) => {
    // Construct the new URL with the customerId as a query parameter
    const newUrl = `${url}?userId=${customerId}&pass=${token}`;
    console.log("newUrl", newUrl);
    // Open the new URL in a new tab
    window.open(newUrl);
  };

  return (
    <>
      <MainWrapper title="Customers">
        <h1 className="mt-4">Customers List</h1>
        {loading && <p>Loading...</p>}
        {error && <p>Error: {error}</p>}
        {customers.length > 0 ? (
          <table className="table table-striped">
            <thead>
              <tr>
                <th>ID</th>
                <th>Name</th>
                <th>Role</th>
                <th>Email</th>
                <th>Phone Number</th>
                {loginedUser.data.roleId == 1 && (
                  <>
                    <th>PeerId</th>
                    <th>Url</th>
                    <th>Connect Button</th>
                  </>
                )}
              </tr>
            </thead>
            <tbody>
              {customers.map((customer) => (
                <tr key={customer.id}>
                  <td>{customer.id}</td>
                  <td>{customer.name}</td>
                  <td>{customer.roleId == 2 ? "dealer" : "Manager"}</td>
                  <td>{customer.email}</td>
                  <td>{customer.phoneNo}</td>
                  {loginedUser.data.roleId == 1 && (
                    <>
                      <td>{customer.peerId || "N/A"}</td>
                      <td>{customer.url || "N/A"}</td>
                      <td>
                        {" "}
                        <button
                          className="btn btn-danger"
                          onClick={() =>
                            handleConnect(
                              customer.id,
                              customer.url,
                              customer.token
                            )
                          }
                        >
                          Connect
                        </button>
                      </td>
                    </>
                  )}
                </tr>
              ))}
            </tbody>
          </table>
        ) : (
          <p>No customers found.</p>
        )}
      </MainWrapper>
    </>
  );
};

export default Customers;
