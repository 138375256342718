import React, { useEffect } from "react";
import MainWrapper from "../Main/MainWrapper";
import { useState } from "react";
import { useNavigate } from "react-router-dom";
import Form from "../Common/Form";
import axios from "axios";
import { getCookie } from "../../utils";

const AddNewDeals = () => {
  const loginedUser = JSON.parse(getCookie("loginData"));
  const navigate = useNavigate();
  // const city = {};

  const [managers, setManagers] = useState([]);
  const [salesperson, setSalesperson] = useState([]);
  // Fetch managers and dealers from API on component mount
  useEffect(() => {
    const fetchManagersAndDealers = async () => {
      try {
        const managerResponse = await axios.get(
          "https://api.menuverse.ai/managers/show",
          {
            headers: {
              "Content-Type": "application/json",
              authorization: `Bearer ${loginedUser.data.token}`,
            },
          }
        );

        const dealerResponse = await axios.get(
          "https://api.menuverse.ai/salespersons/show",
          {
            headers: {
              "Content-Type": "application/json",
              authorization: `Bearer ${loginedUser.data.token}`,
            },
          }
        );

        setManagers(
          managerResponse.data.data.map((manager) => ({
            name: manager.name,
            id: manager.id,
          }))
        );
        setSalesperson(
          dealerResponse.data.data.map((dealer) => ({
            name: dealer.name,
            id: dealer.id,
          }))
        );
        console.log(
          "managers",
          managerResponse.data.data.map((manager) => ({
            name: manager.name,
            id: manager.id,
          }))
        );
        console.log("managers2", managers);
      } catch (error) {
        console.error("Failed to fetch managers or dealers:", error);
      }
    };

    fetchManagersAndDealers();
  }, [loginedUser.data.token]);

  const [states, setStates] = useState([]);
  const [cities, setCities] = useState([]);

  const [dealers, setDealers] = useState([]);

  const languages = [
    { id: "English (United States)", name: "English (United States)" },
    { id: "English (Canada)", name: "English (Canada)" },
    { id: "Espanol (Maxico)", name: "Espanol (Maxico)" },
    { id: "Francais (Canada)", name: "Francais (Canada)" },
  ];

  const getDealers = async () => {
    const repsonseData = await fetch("https://api.menuverse.ai/dealers/show", {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        authorization: "Bearer " + loginedUser.data.token,
      },
    });

    const response = await repsonseData.json();

    console.log("Dealers", response);

    setDealers(response.data);
  };

  useEffect(() => {
    getDealers();
  }, []);

  const statesData = async () => {
    try {
      const response = await fetch("https://api.menuverse.ai/states/show", {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          authorization: "Bearer " + loginedUser.data.token,
        },
      });

      if (response.status === 201 || response.status === 200) {
      } else if (response.status === 500) {
        const result = await response.json();
        window.toastr.error(result.message);
      } else if (response.status === 401 || response.status === 403) {
        navigate("/login");
      }

      const responseData = await response.json();

      setStates(responseData.data);
    } catch (error) {
      console.log("Error Occured", error);
    }
  };

  const ctiesData = async () => {
    try {
      const response = await fetch("https://api.menuverse.ai/cities/show", {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          authorization: "Bearer " + loginedUser.data.token,
        },
      });

      if (response.status === 201 || response.status === 200) {
      } else if (response.status === 500) {
        const result = await response.json();
        window.toastr.error(result.message);
      } else if (response.status === 401 || response.status === 403) {
        navigate("/login");
      }

      const responseData = await response.json();

      setCities(responseData.data);
    } catch (error) {
      console.log("Error Occured", error);
    }
  };

  useEffect(() => {
    statesData();

    ctiesData();
  }, []);

  const formFields = [
    {
      fieldName: "dealer_id",
      fieldType: "select",
      fieldMask: "",
      fieldMaxLength: "",
      fieldRequired: "true",
      fieldDefaultValue: "",
      fieldValue: "",
      fieldOptions: dealers,
      dbType: "integer",
      fieldLabel: "Dealer",
    },
    {
      fieldName: "deal_number",
      fieldType: "number",
      fieldMask: "",
      fieldMaxLength: "100",
      fieldDefaultValue: "",
      fieldLabel: "Deal Number",
    },
    {
      fieldName: "deal_type",
      fieldType: "select",
      fieldMask: "",
      fieldMaxLength: "",
      fieldDefaultValue: "",
      fieldLabel: "Deal Type",
      fieldOptions: [
        { id: "Balloon", name: "Balloon" },
        { id: "Cash", name: "Cash" },
        { id: "Finance", name: "Finance" },
        { id: "Lease", name: "Lease" },
        { id: "Lease APR", name: "Lease APR" },
      ],
      dbType: "string",
    },
    {
      fieldName: "firstName",
      fieldType: "text",
      fieldMask: "",
      fieldMaxLength: "100",
      fieldRequired: "true",
      fieldDefaultValue: "",
      fieldLabel: "First Name",
    },
    {
      fieldName: "middleName",
      fieldType: "text",
      fieldMask: "",
      fieldMaxLength: "100",
      fieldDefaultValue: "",
      fieldLabel: "Middle Name",
    },
    {
      fieldName: "lastName",
      fieldType: "text",
      fieldMask: "",
      fieldMaxLength: "100",
      fieldRequired: "true",
      fieldDefaultValue: "",
      fieldLabel: "Last Name",
    },
    {
      fieldName: "email",
      fieldType: "text",
      fieldMask: "",
      fieldMaxLength: "100",
      fieldDefaultValue: "",
      fieldLabel: "Email Address",
    },

    {
      fieldName: "vinNumber",
      fieldType: "text",
      fieldMask: "",
      fieldMaxLength: "100",
      fieldRequired: "true",
      fieldDefaultValue: "",
      fieldLabel: "VIN Number",
    },

    {
      fieldName: "phoneNumber",
      fieldType: "text",
      fieldMask: "",
      fieldMaxLength: "100",
      fieldDefaultValue: "",
      fieldLabel: "Phone",
    },
    {
      fieldName: "streetAddress",
      fieldType: "textarea",
      fieldMask: "",
      fieldMaxLength: "",
      fieldDefaultValue: "",
      fieldValue: "",
      fieldLabel: "Address",
    },
    {
      fieldName: "city",
      fieldType: "text",
      fieldMask: "",
      fieldMaxLength: "",
      fieldDefaultValue: "",
      fieldValue: "",
      // fieldOptions: cities,
      // dbType: "integer",
      fieldLabel: "City",
    },
    {
      fieldName: "state",
      fieldType: "select",
      fieldMask: "",
      fieldMaxLength: "",
      fieldDefaultValue: "",
      fieldValue: "",
      fieldOptions: states,
      dbType: "integer",
      fieldLabel: "State",
    },

    {
      fieldName: "zipCode",
      fieldType: "text",
      fieldMask: "",
      fieldMaxLength: "100",
      fieldDefaultValue: "",
      fieldLabel: "Zip Code",
    },
    {
      fieldName: "vehicle",
      fieldType: "text",
      fieldMask: "",
      fieldMaxLength: "100",
      fieldRequired: "true",
      fieldDefaultValue: "",
      fieldLabel: "Vehicle",
    },
    {
      fieldName: "vehicleColor",
      fieldType: "text",
      fieldMask: "",
      fieldMaxLength: "100",
      fieldDefaultValue: "",
      fieldLabel: "Vehicle Color",
    },
    {
      fieldName: "lender_name",
      fieldType: "text",
      fieldMask: "",
      fieldMaxLength: "100",
      fieldDefaultValue: "",
      fieldLabel: "Lender",
    },
    {
      fieldName: "tax_group",
      fieldType: "select",
      fieldMask: "",
      fieldMaxLength: "100",
      fieldDefaultValue: "",
      fieldLabel: "Tax Group",
      fieldOptions: [
        { id: "CA - Tax Group", name: "CA - Tax Group" },
        { id: "More", name: "More" },
      ],
      dbType: "string",
    },
    {
      fieldName: "free_add_on",
      fieldType: "number",
      fieldMask: "",
      fieldMaxLength: "100",
      fieldDefaultValue: "",
      fieldLabel: "Free Add On",
    },
    {
      fieldName: "taxes",
      fieldType: "number",
      fieldMask: "",
      fieldMaxLength: "100",
      fieldDefaultValue: "",
      fieldLabel: "Taxes",
    },
    {
      fieldName: "fees",
      fieldType: "number",
      fieldMask: "",
      fieldMaxLength: "100",
      fieldDefaultValue: "",
      fieldLabel: "Fees",
    },

    {
      fieldName: "loan_term",
      fieldType: "number",
      fieldMask: "",
      fieldMaxLength: "100",
      fieldDefaultValue: "",
      fieldLabel: "Loan Term",
    },
    {
      fieldName: "purchase_price",
      fieldType: "number",
      fieldMask: "",
      fieldMaxLength: "100",
      fieldDefaultValue: "",
      fieldLabel: "Purchase Price",
    },
    {
      fieldName: "interest_rate",
      fieldType: "number",
      fieldMask: "",
      fieldMaxLength: "100",
      fieldDefaultValue: "",
      fieldLabel: "Interest Rate",
    },
    {
      fieldName: "down_payment",
      fieldType: "number",
      fieldMask: "",
      fieldMaxLength: "100",
      fieldDefaultValue: "",
      fieldLabel: "Down Payment",
    },
    {
      fieldName: "vehicle_payment",
      fieldType: "number",
      fieldMask: "",
      fieldMaxLength: "100",
      fieldDefaultValue: "",
      fieldLabel: "Vehicle Payment",
    },
    {
      fieldName: "net_trade",
      fieldType: "number",
      fieldMask: "",
      fieldMaxLength: "100",
      fieldDefaultValue: "",
      fieldLabel: "Net Trade",
    },
    {
      fieldName: "protection_items",
      fieldType: "number",
      fieldMask: "",
      fieldMaxLength: "100",
      fieldDefaultValue: "",
      fieldLabel: "Protection Items",
    },
    {
      fieldName: "rebate",
      fieldType: "number",
      fieldMask: "",
      fieldMaxLength: "100",
      fieldDefaultValue: "",
      fieldLabel: "Rebate",
    },
    {
      fieldName: "total_monthly_payment",
      fieldType: "number",
      fieldMask: "",
      fieldMaxLength: "100",
      fieldDefaultValue: "",
      fieldLabel: "Total Monthly Payment",
    },

    {
      fieldName: "msrp",
      fieldType: "number",
      fieldMask: "",
      fieldMaxLength: "100",
      fieldDefaultValue: "",
      fieldLabel: "MSRP",
    },
    {
      fieldName: "sales_person",
      fieldType: "select",
      fieldOptions: salesperson,
      fieldLabel: "Sales Person",
    },
    {
      fieldName: "sales_person2",
      fieldType: "select",
      fieldOptions: salesperson,
      fieldLabel: "Sales Person 2",
    },
    {
      fieldName: "business_manager",
      fieldType: "select",
      fieldOptions: managers,
      fieldLabel: "Business Manager",
    },
    {
      fieldName: "sales_manager",
      fieldType: "select",
      fieldOptions: managers,
      fieldLabel: "Sales Manager",
    },
    {
      fieldName: "amount_financed",
      fieldType: "number",
      fieldMask: "",
      fieldMaxLength: "100",
      fieldDefaultValue: "",
      fieldLabel: "Amount Financed",
    },
    {
      fieldName: "max_approved",
      fieldType: "number",
      fieldMask: "",
      fieldMaxLength: "100",
      fieldDefaultValue: "",
      fieldLabel: "Max Approved",
    },
  ];

  const formDefinition = {
    type: "add",
    URL: "https://api.menuverse.ai/newdeal/create",
    successRedirect: "editform",
    editRedirect: "/edit-newdeal",
  };

  return (
    <>
      <MainWrapper title="Add New Deal">
        <div className="card card-default" bis_skin_checked="1">
          <div className="card-header" bis_skin_checked="1">
            {/* <h2></h2> */}
          </div>
          <div className="card-body" bis_skin_checked="1">
            <div
              className="row"
              bis_skin_checked="1"
              style={{
                border: "1px solid #eee",
                paddingTop: "20px",
                paddingBottom: "20px",
              }}
            >
              <div className="col-xl-12">
                <Form formFields={formFields} {...formDefinition} />
              </div>
            </div>
          </div>
        </div>
      </MainWrapper>
    </>
  );
};

export default AddNewDeals;
