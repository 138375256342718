import React from "react";
import MainWrapper from "../Main/MainWrapper";
import { useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { useEffect } from "react";
import Form from "../Common/Form";
import { getCookie } from "../../utils";

const EditProductType = () => {
  const loginedUser = JSON.parse(getCookie("loginData"));
  const navigate = useNavigate();
  const [serviceCategorieData, setserviceCategorieData] = useState([]);
  const serviceCategories = async () => {
    try {
      const response = await fetch(
        "https://api.menverse.ai/service_category/show",
        {
          method: "GET",
          headers: {
            "Content-Type": "application/json",
            authorization: "Bearer " + loginedUser.data.token,
          },
        }
      );

      if (response.status === 201 || response.status === 200) {
      } else if (response.status === 500) {
        const result = await response.json();
        window.toastr.error(result.message);
      } else if (response.status === 401 || response.status === 403) {
        navigate("/login");
      }

      const responseData = await response.json();
      setserviceCategorieData(responseData.data);
    } catch (error) {
      console.log("Error Occured", error);
    }
  };

  console.log("serviceCategorieData ", serviceCategorieData);

  const [serviceProductTypeData, setserviceProductTypeData] = useState([]);
  const serviceProductType = async () => {
    try {
      const response = await fetch(
        "https://api.menverse.ai/service_product_type/show",
        {
          method: "GET",
          headers: {
            "Content-Type": "application/json",
            authorization: "Bearer " + loginedUser.data.token,
          },
        }
      );

      if (response.status === 201 || response.status === 200) {
      } else if (response.status === 500) {
        const result = await response.json();
        window.toastr.error(result.message);
      } else if (response.status === 401 || response.status === 403) {
        navigate("/login");
      }

      const responseData = await response.json();
      setserviceProductTypeData(responseData.data);
    } catch (error) {
      console.log("Error Occured", error);
    }
  };

  console.log("serviceProductTypeData ", serviceProductTypeData);

  const [serviceVendorData, setserviceVendorData] = useState([]);
  const serviceVendor = async () => {
    try {
      const response = await fetch("https://api.menverse.ai/vendor/show", {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          authorization: "Bearer " + loginedUser.data.token,
        },
      });

      if (response.status === 201 || response.status === 200) {
      } else if (response.status === 500) {
        const result = await response.json();
        window.toastr.error(result.message);
      } else if (response.status === 401 || response.status === 403) {
        navigate("/login");
      }

      const responseData = await response.json();
      setserviceVendorData(responseData.data);
    } catch (error) {
      console.log("Error Occured", error);
    }
  };

  console.log("serviceVendorData ", serviceVendorData);

  const [servicePartnerData, setservicePartnerData] = useState([]);
  const servicePartner = async () => {
    try {
      const response = await fetch("https://api.menverse.ai/partner/show", {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          authorization: "Bearer " + loginedUser.data.token,
        },
      });

      if (response.status === 201 || response.status === 200) {
      } else if (response.status === 500) {
        const result = await response.json();
        window.toastr.error(result.message);
      } else if (response.status === 401 || response.status === 403) {
        navigate("/login");
      }

      const responseData = await response.json();
      setservicePartnerData(responseData.data);
    } catch (error) {
      console.log("Error Occured", error);
    }
  };

  console.log("servicePartnerData ", servicePartnerData);

  const [serviceDMSDataType, setserviceDMSDataType] = useState([]);
  const serviceDmsData = async () => {
    try {
      const response = await fetch("https://api.menverse.ai/dmscode/show", {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          authorization: "Bearer " + loginedUser.data.token,
        },
      });

      if (response.status === 201 || response.status === 200) {
      } else if (response.status === 500) {
        const result = await response.json();
        window.toastr.error(result.message);
      } else if (response.status === 401 || response.status === 403) {
        navigate("/login");
      }

      const responseData = await response.json();
      setserviceDMSDataType(responseData.data);
    } catch (error) {
      console.log("Error Occured", error);
    }
  };

  console.log("serviceDMSDataType ", serviceDMSDataType);

  const [serviceSalesToolsData, setserviceSalesToolsData] = useState([]);
  const serviceSalestools = async () => {
    try {
      const response = await fetch("https://api.menverse.ai/salestool/show", {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          authorization: "Bearer " + loginedUser.data.token,
        },
      });

      if (response.status === 201 || response.status === 200) {
      } else if (response.status === 500) {
        const result = await response.json();
        window.toastr.error(result.message);
      } else if (response.status === 401 || response.status === 403) {
        navigate("/login");
      }

      const responseData = await response.json();

      setserviceSalesToolsData(responseData.data);
    } catch (error) {
      console.log("Error Occured", error);
    }
  };

  console.log("serviceSalesToolsData ", serviceSalesToolsData);

  useEffect(() => {
    serviceCategories();
    serviceProductType();
    serviceVendor();
    servicePartner();
    serviceDmsData();
    serviceSalestools();
  }, []);

  const { id } = useParams();

  const [formStructure, setFormStructure] = useState([]);
  useEffect(() => {
    const formFields = [
      {
        fieldName: "name",
        fieldType: "text",
        fieldMask: "",
        fieldMaxLength: "100",
        fieldRequired: "true",
        fieldDefaultValue: "",
        fieldLabel: "Product Name",
      },
      {
        fieldName: "category_id",
        fieldType: "select",
        fieldMask: "",
        fieldMaxLength: "",
        fieldRequired: "true",
        fieldDefaultValue: "",
        fieldValue: "",
        fieldOptions: serviceCategorieData,
        dbType: "integer",
        fieldLabel: "Category",
      },
      {
        fieldName: "productype_id",
        fieldType: "select",
        fieldMask: "",
        fieldMaxLength: "",
        fieldRequired: "true",
        fieldDefaultValue: "",
        fieldValue: "",
        fieldOptions: serviceProductTypeData,
        dbType: "integer",
        fieldLabel: "Type",
      },
      {
        fieldName: "vendor_id",
        fieldType: "select",
        fieldMask: "",
        fieldMaxLength: "",
        fieldRequired: "true",
        fieldDefaultValue: "",
        fieldValue: "",
        fieldOptions: serviceVendorData,
        dbType: "integer",
        fieldLabel: "Provider",
      },
      {
        fieldName: "use_price_form_rating",
        fieldType: "hidden",
        fieldMask: "",
        fieldMaxLength: "",

        fieldDefaultValue: "",
        fieldValue: "",
        //   fieldOptions: serviceCategoriesData,
        //   dbType: "integer",
        fieldLabel: "",
      },
      {
        fieldName: "partner_id",
        fieldType: "select",
        fieldMask: "",
        fieldMaxLength: "",
        fieldRequired: "true",
        fieldDefaultValue: "",
        fieldValue: "",
        fieldOptions: servicePartnerData,
        dbType: "integer",
        fieldLabel: "Partner",
      },
      {
        fieldName: "dmscode_id",
        fieldType: "select",
        fieldMask: "",
        fieldMaxLength: "",
        fieldRequired: "true",
        fieldDefaultValue: "",
        fieldValue: "",
        fieldOptions: serviceDMSDataType,
        dbType: "integer",
        fieldLabel: "DMS Code",
      },
      {
        fieldName: "show_advance_option",
        fieldType: "hidden",
        fieldMask: "",
        fieldMaxLength: "",
        fieldDefaultValue: "",
        fieldValue: "",
        //   fieldOptions: serviceCategoriesData,
        //   dbType: "integer",
      },
      {
        fieldName: "active",
        fieldType: "hidden",
        fieldMask: "",
        fieldMaxLength: "",
        fieldDefaultValue: "",
        fieldValue: "",
        //   fieldOptions: serviceCategoriesData,
        //   dbType: "integer",
      },
      {
        fieldName: "salestools_id",
        fieldType: "select",
        fieldMask: "",
        fieldMaxLength: "",
        fieldRequired: "true",
        fieldDefaultValue: "",
        fieldValue: "",
        fieldOptions: serviceSalesToolsData,
        dbType: "integer",
        fieldLabel: "Sales Tool",
      },
      {
        fieldName: "description",
        fieldType: "text",
        fieldMask: "",
        fieldMaxLength: "",
        fieldRequired: "true",
        fieldDefaultValue: "",
        fieldValue: "",
        //   fieldOptions: serviceCategoriesData,
        //   dbType: "integer",
        fieldLabel: "Description",
      },
      {
        fieldName: "sell_price",
        fieldType: "text",
        fieldMask: "",
        fieldMaxLength: "",
        fieldRequired: "true",
        fieldDefaultValue: "",
        fieldValue: "",
        //   fieldOptions: serviceCategoriesData,
        dbType: "integer",
        fieldLabel: "Selling Price",
      },
      {
        fieldName: "FandI_Cost",
        fieldType: "text",
        fieldMask: "",
        fieldMaxLength: "",
        fieldRequired: "true",
        fieldDefaultValue: "",
        fieldValue: "",
        //   fieldOptions: serviceCategoriesData,
        dbType: "integer",
        fieldLabel: "F&I Cost",
      },
      {
        fieldName: "months",
        fieldType: "text",
        fieldMask: "",
        fieldMaxLength: "",
        fieldRequired: "true",
        fieldDefaultValue: "",
        fieldValue: "",
        //   fieldOptions: serviceCategoriesData,
        // dbType: "integer",
        fieldLabel: "Months",
      },
      {
        fieldName: "miles",
        fieldType: "text",
        fieldMask: "",
        fieldMaxLength: "",
        fieldRequired: "true",
        fieldDefaultValue: "",
        fieldValue: "",
        //   fieldOptions: serviceCategoriesData,
        dbType: "integer",
        fieldLabel: "Miles",
      },
      {
        fieldName: "deductible",
        fieldType: "text",
        fieldMask: "",
        fieldMaxLength: "",
        fieldRequired: "true",
        fieldDefaultValue: "",
        fieldValue: "",
        // fieldOptions: serviceCategoriesData,
        dbType: "integer",
        fieldLabel: "Deductible",
      },
    ];
    const getServiceCategoryData = async () => {
      let record = await fetch("https://api.menverse.ai/product/show/" + id, {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          authorization: "Bearer " + loginedUser.data.token,
        },
      });

      if (record.status === 201 || record.status === 200) {
      } else if (record.status === 500) {
        const result = await record.json();
        window.toastr.error(result.message);
      } else if (record.status === 401 || record.status === 403) {
        navigate("/login");
      }
      record = await record.json();
      const dealerData = record.data;

      setFormStructure(
        formFields.map((field) => {
          return { ...field, fieldDefaultValue: dealerData[field.fieldName] };
        })
      );
    };
    getServiceCategoryData();
  }, [
    serviceCategorieData,
    serviceProductTypeData,
    serviceVendorData,
    servicePartnerData,
    serviceDMSDataType,
    serviceSalesToolsData,
  ]);

  const formDefinition = {
    type: "edit",
    URL: "https://api.menverse.ai/product/update/" + id,
    successRedirect: "/products",
  };

  return (
    <>
      <MainWrapper title="Edit Product ">
        <div className="card card-default" bis_skin_checked="1">
          <div className="card-header" bis_skin_checked="1">
            {/* <h2></h2> */}
          </div>
          <div className="card-body" bis_skin_checked="1">
            <div
              className="row"
              bis_skin_checked="1"
              style={{
                border: "1px solid #eee",
                paddingTop: "20px",
                paddingBottom: "20px",
              }}
            >
              <div className="col-xl-12">
                <Form formFields={formStructure} {...formDefinition} />
              </div>
            </div>
          </div>
        </div>
      </MainWrapper>
    </>
  );
};

export default EditProductType;
