import React from "react";
import MainWrapper from "../Main/MainWrapper";
import { useEffect, useState } from "react";
import axios from "axios";
import jsPDF from "jspdf";
import html2canvas from "html2canvas";
import { getCookie } from "../../utils";

const Reports = () => {
  const loginedUser = JSON.parse(getCookie("loginData"));
  const token = loginedUser.data.token;

  const [manager, setManager] = useState([]);
  const [data, setData] = useState({});

  useEffect(() => {
    const fetchManager = async () => {
      try {
        const response = await axios.get(
          "https://api.menuverse.ai/managers/show",
          {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          }
        );
        setManager(response.data.data);
      } catch (error) {
        console.error("Error fetching managers:", error);
      }
    };

    fetchManager();
  }, []);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await axios.get(
          "https://api.menuverse.ai/reports/show",
          {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          }
        );
        setData(response.data.data);
      } catch (error) {
        console.error("Error fetching reports data:", error);
      }
    };

    fetchData();
  }, []);

  console.log("data", data);

  const downloadPDF = () => {
    const input = document.getElementById("pdf-content");
    html2canvas(input, { scale: 2 }) // Increase scale for better resolution
      .then((canvas) => {
        const imgData = canvas.toDataURL("image/png");
        const pdf = new jsPDF({
          orientation: "portrait",
          unit: "mm",
          format: "a4",
          putOnlyUsedFonts: true,
          floatPrecision: 16, // Optional: increase precision
        });

        const imgWidth = 210; // A4 width in mm
        const pageHeight = 295; // A4 height in mm
        const imgHeight = (canvas.height * imgWidth) / canvas.width;
        let heightLeft = imgHeight;
        let position = 0;

        // Add image and handle page breaks
        if (heightLeft >= pageHeight) {
          // If the image is larger than one page
          while (heightLeft >= 0) {
            pdf.addImage(imgData, "PNG", 0, position, imgWidth, imgHeight);
            heightLeft -= pageHeight;
            position -= pageHeight;
            if (heightLeft >= 0) {
              pdf.addPage();
            }
          }
        } else {
          // If the image fits on one page
          pdf.addImage(imgData, "PNG", 0, 0, imgWidth, imgHeight);
        }

        pdf.save("reports.pdf");
      })
      .catch((error) => {
        console.error("Error generating PDF:", error);
      });
  };

  // Create a mapping for manager counts from groupedDeals
  const managerCounts = {};
  const vehicleCountsByManager = {};
  if (data.groupedDeals) {
    data.groupedDeals.forEach((deal) => {
      managerCounts[deal.sales_manager] = deal.managerCount;
      vehicleCountsByManager[deal.sales_manager] = deal.vehicleCounts;
    });
  }

  return (
    <div>
      <MainWrapper title={"Reports"} className="">
        <div>
          <button
            onClick={downloadPDF}
            className="btn btn-danger"
            style={{ marginTop: "20px", color: "white", background: "red" }}
          >
            Download PDF
          </button>
        </div>
        <div id="pdf-content">
          <div className="text-dark m-5 p-5">
            <h1 className="text-center font-weight-bold">REPORTS</h1>

            <h1 className="font-weight-bold">
              {" "}
              F&I Summary<br></br>
              Fairfield Group
            </h1>
            <p className="h5">
              {" "}
              Date Range : 10/1/2024 to 10/31/2024<br></br>
              Filtered On Type(s) : Retail, Fleet<br></br>
              Filtered on Status : Sold, Closed<br></br>
              Including Class : New, Used and Trxn : Finance, Lease, Cash,
              Balloon and Products : Back
            </p>

            <table
              border="1"
              cellPadding="10"
              cellSpacing="0"
              style={{ width: "100%", borderCollapse: "collapse" }}
            >
              <thead>
                <tr>
                  <th></th>
                  {manager.map((item, index) => (
                    <th key={index}>{item.name}</th>
                  ))}
                  <th>Total</th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td>TOTAL UNITS</td>
                  {manager.map((item, index) => (
                    <td key={index}>{managerCounts[item.id] || 0}</td>
                  ))}
                  <td>{data.totalCount}</td>
                </tr>
                <tr>
                  <td>New</td>
                  {manager.map((item, index) => (
                    <td key={index}>
                      {vehicleCountsByManager[item.id]?.New || 0}
                    </td>
                  ))}
                  <td>
                    {data.groupedDeals?.reduce(
                      (acc, deal) => acc + (deal.vehicleCounts.New || 0),
                      0
                    ) || 0}
                  </td>
                </tr>
                <tr>
                  <td>Used</td>
                  {manager.map((item, index) => (
                    <td key={index}>
                      {vehicleCountsByManager[item.id]?.Used || 0}
                    </td>
                  ))}
                  <td>
                    {data.groupedDeals?.reduce(
                      (acc, deal) => acc + (deal.vehicleCounts.Used || 0),
                      0
                    ) || 0}
                  </td>
                </tr>
                <tr>
                  <td>Financed</td>
                  {manager.map((item, index) => (
                    <td key={index}>
                      {vehicleCountsByManager[item.id]?.Financed || 0}
                    </td>
                  ))}
                  <td>
                    {data.groupedDeals?.reduce(
                      (acc, deal) => acc + (deal.vehicleCounts.Financed || 0),
                      0
                    ) || 0}
                  </td>
                </tr>
                <tr>
                  <td>Leased</td>
                  {manager.map((item, index) => (
                    <td key={index}>
                      {vehicleCountsByManager[item.id]?.Leased || 0}
                    </td>
                  ))}
                  <td>
                    {data.groupedDeals?.reduce(
                      (acc, deal) => acc + (deal.vehicleCounts.Leased || 0),
                      0
                    ) || 0}
                  </td>
                </tr>
                <tr>
                  <td>Cash</td>
                  {manager.map((item, index) => (
                    <td key={index}>
                      {vehicleCountsByManager[item.id]?.Cash || 0}
                    </td>
                  ))}
                  <td>
                    {data.groupedDeals?.reduce(
                      (acc, deal) => acc + (deal.vehicleCounts.Cash || 0),
                      0
                    ) || 0}
                  </td>
                </tr>

                <tr>
                  <td>F&I TOTAL POLICIES</td>
                </tr>
                <tr>
                  <td>Products Per Unit Sold</td>
                </tr>
                <tr>
                  <td>Total F&I Gross</td>
                </tr>
                <tr>
                  <td>Avg. Gross Per Policy</td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      </MainWrapper>
    </div>
  );
};

export default Reports;
