import React from "react";
import MainWrapper from "../Main/MainWrapper";
import { useEffect, useState } from "react";
import Table from "../Common/Table";
import { Link } from "react-router-dom";

const Tests = () => {
  const apiData = {
    url: "https://api.menverse.ai/tests/show",
    method: "GET",
    editUrl: "/edit-test",
    deleteUrl: "https://api.menverse.ai/tests/delete",
  };
  const excludedColumns = ["id"];

  return (
    <>
      <MainWrapper title={"Tests"}>
        <div className="card card-default" bis_skin_checked="1">
          <div className="card-header" bis_skin_checked="1">
            <h2></h2>
          </div>
          <div className="card-body" bis_skin_checked="1">
            <Link to="/add-test" className="sidenav-item-link">
              <button className="btn btn-priimary">Add Test</button>
            </Link>

            <Table apiData={apiData} excludedColumns={excludedColumns} />
          </div>
        </div>
      </MainWrapper>
    </>
  );
};

export default Tests;
