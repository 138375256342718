import React, { useEffect } from "react";
import MainWrapper from "../Main/MainWrapper";
import { useState } from "react";
import { useNavigate } from "react-router-dom";
import Form from "../Common/Form";
import { getCookie } from "../../utils";

const AddQuestion = () => {
  const loginedUser = JSON.parse(getCookie("loginData"));
  const navigate = useNavigate();

  const cities = [
    { name: "Amritsar", id: 1 },
    { name: "Jalandhar", id: 2 },
    { name: "Ludhiana", id: 3 },
  ];
  const states = [
    { name: "Delhi", id: 1 },
    { name: "Punjab", id: 2 },
    { name: "Haryana", id: 3 },
  ];

  const dynamicValues = [
    { name: "Name", id: "%name%" },
    { name: "City", id: "%city%" },
    { name: "State", id: "%state%" },
    { name: "Car Miles", id: "%miles%" },
    { name: "Trading", id: "%trading%" },
  ];
  const types = [
    { name: "Question", id: "Question" },
    { name: "Game", id: "Game" },
  ];
  const questionTypes = [
    { name: "Checkbox", id: "checkbox" },
    { name: "Slider", id: "slider" },
    { name: "Number", id: "number" },
  ];
  const [services, setSevices] = useState([]);
  useEffect(() => {
    const fetchService = async () => {
      const response = await fetch(
        "https://api.menuverse.ai/service_category/show",
        {
          method: "get",
          headers: {
            "Content-Type": "application/json",
            authorization: "Bearer " + loginedUser.data.token,
          },
        }
      );

      if (response.status === 201 || response.status === 200) {
      } else if (response.status === 500) {
        const result = await response.json();
        window.toastr.error(result.message);
      } else if (response.status === 401 || response.status === 403) {
        navigate("/login");
      }

      const responseData = await response.json();

      setSevices(responseData.data);

      console.log("Services Data", responseData);
    };
    fetchService();
  }, []);

  const [showDefaultRadio, setshowDefaultRadio] = useState(true);

  const handleShowDefaults = (e) => {
    if (e == "checkbox") {
      setshowDefaultRadio(true);
    } else if (e == "slider") {
      setshowDefaultRadio(false);
    } else if (e == "number") {
      setshowDefaultRadio(false);
    }
  };

  const formFields = [
    {
      fieldName: "title",
      fieldType: "textarea",
      fieldMask: "",
      fieldMaxLength: "225",
      fieldRequired: "true",
      fieldDefaultValue: "",
      fieldLabel: "Question",
    },

    {
      fieldName: "type",
      fieldType: "select",
      fieldMask: "",
      fieldMaxLength: "",
      fieldRequired: "true",
      fieldDefaultValue: "",
      fieldValue: "",
      fieldOptions: types,
      dbType: "string",
      fieldLabel: "Next slide would be question or a game?",
    },
    {
      fieldName: "questionType",
      fieldType: "select",
      fieldMask: "",
      fieldMaxLength: "",
      fieldRequired: "true",
      fieldDefaultValue: "",
      fieldValue: "",
      fieldOptions: questionTypes,
      dbType: "string",
      fieldLabel: "Answer Type",
    },
    {
      fieldName: "jackpot",
      fieldType: "text",
      fieldMask: "",
      fieldMaxLength: "100",

      fieldDefaultValue: "",
      fieldLabel:
        "Any Jackpot connected to this question?  <em style='color: #333; font-size: 14px; font-weight: 400'>(Optional)</em>",
    },
    {
      fieldName: "jackpotType",
      fieldType: "text",
      fieldMask: "",
      fieldMaxLength: "100",

      fieldDefaultValue: "",
      fieldLabel:
        "Type of Jackpot?  <em style='color: #333; font-size: 14px; font-weight: 400'>(Optional)</em>",
    },
    {
      fieldName: "video",
      fieldType: "text",
      fieldMask: "",
      fieldMaxLength: "100",

      fieldDefaultValue: "",
      fieldLabel:
        "Video URL  <em style='color: #333; font-size: 14px; font-weight: 400'>(Optional)</em>",
    },
    {
      fieldName: "questionOptions",
      fieldType: "multiples",
      fieldMask: "",
      fieldMaxLength: "100",
      fieldRequired: "true",
      fieldDefaultValue: "",
      multiples: [
        {
          fieldName: "title",
          fieldType: "text",
          fieldMask: "",
          fieldMaxLength: "100",
          fieldRequired: "true",
          fieldDefaultValue: "",
          fieldLabel: "Answer Title/Text",
        },
        {
          fieldName: "range",
          fieldType: "text",
          fieldMask: "",
          fieldMaxLength: "100",

          fieldDefaultValue: "",
          fieldLabel:
            "Range If Any <em style='color: #333; font-size: 14px; font-weight: 400'>(This is mostly used in slider type of questions)</em>",
        },
        {
          fieldName: "threshold",
          fieldType: "number",
          fieldMask: "",
          fieldMaxLength: "100",

          fieldDefaultValue: "",
          fieldLabel:
            "Threshold <em style='color: #333; font-size: 14px; font-weight: 400'>(This is mostly used to refer service)</em>",
        },
        {
          fieldName: "increment_value",
          fieldType: "text",
          fieldMask: "",
          fieldMaxLength: "100",

          fieldDefaultValue: "",
          fieldLabel:
            "Increment Value <em style='color: #333; font-size: 14px; font-weight: 400'>(This is used if you question is of number type)</em>",
        },
        {
          fieldName: "default",
          fieldType: "radio",
          fieldMask: "",
          fieldMaxLength: "100",
          fieldRequired: "false",
          fieldDefaultValue: "No",
          fieldValue: "Yes",
          fieldLabel:
            "Set as Default <em style='color: #333; font-size: 14px; font-weight: 400'>(Set default selected option if your question of checkbox or slider type)</em>",
        },
        {
          fieldName: "default",
          fieldType: "radio",
          fieldMask: "",
          fieldMaxLength: "100",
          fieldRequired: "false",
          fieldDefaultValue: "No",
          fieldValue: "No",
          fieldLabel: "Set as Default",
        },
        {
          fieldName: "default_value",
          fieldType: "text",
          fieldMask: "",
          fieldMaxLength: "100",

          fieldDefaultValue: "",
          fieldLabel:
            "Default value <em style='color: #333; font-size: 14px; font-weight: 400'>(Set default number what should be starting point if your question of number type)</em>",
        },
        {
          fieldName: "externalurl",
          fieldType: "text",
          fieldMask: "",
          fieldMaxLength: "100",

          fieldDefaultValue: "",
          fieldLabel:
            "External URL If Any <em style='color: #333; font-size: 14px; font-weight: 400'>(If you want to take user to any other site)</em>",
        },
        {
          fieldName: "service_id",
          fieldType: "select",
          fieldMask: "",
          fieldMaxLength: "",
          fieldRequired: "true",
          fieldDefaultValue: "",
          fieldValue: "",
          fieldOptions: services,
          dbType: "integer",
          fieldLabel:
            "Service <em style='color: #333; font-size: 14px; font-weight: 400'>(Select the service which should be selected as recommended according to this answer)</em>",
        },
      ],
    },
  ];

  const formDefinition = {
    type: "add",
    URL: "https://api.menuverse.ai/questions/create",
    successRedirect: "/questions",
  };

  return (
    <>
      <MainWrapper title="Add Question">
        <div className="card card-default" bis_skin_checked="1">
          <div className="card-header" bis_skin_checked="1">
            {/* <h2></h2> */}
          </div>
          <div className="card-body" bis_skin_checked="1">
            <div
              className="row"
              bis_skin_checked="1"
              style={{
                border: "1px solid #eee",
                paddingTop: "20px",
                paddingBottom: "20px",
              }}
            >
              <div className="col-xl-12">
                <Form
                  formFields={formFields}
                  {...formDefinition}
                  addFormData={formDefinition}
                  dynamicValues={dynamicValues}
                  showDefaultRadio={showDefaultRadio}
                  onSelectOption={handleShowDefaults}
                />
              </div>
            </div>
          </div>
        </div>
      </MainWrapper>
    </>
  );
};

export default AddQuestion;
