import React, { useEffect, useState } from "react";
import MainWrapper from "../Main/MainWrapper";
import axios from "axios";
import jsPDF from "jspdf";
import html2canvas from "html2canvas";
import { getCookie } from "../../utils";

const Comparison = () => {
  const loginedUser = JSON.parse(getCookie("loginData"));
  const token = loginedUser.data.token;

  const [manager, setManager] = useState([]);
  const [data, setData] = useState({});

  // Fetch managers data
  useEffect(() => {
    const fetchManager = async () => {
      try {
        const response = await axios.get(
          "https://api.menuverse.ai/managers/show",
          {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          }
        );
        setManager(response.data.data);
      } catch (error) {
        console.error("Error fetching managers:", error);
      }
    };

    fetchManager();
  }, [token]); // Add token to dependencies

  // Fetch comparison report data
  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await axios.get(
          "https://api.menuverse.ai/comparisonreports/show",
          {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          }
        );
        setData(response.data.data);
      } catch (error) {
        console.error("Error fetching reports data:", error);
      }
    };

    fetchData();
  }, [token]); // Add token to dependencies

  console.log("data", data);

  // Download PDF
  const downloadPDF = () => {
    const input = document.getElementById("pdf-content");
    html2canvas(input, { scale: 2 }) // Increase scale for better resolution
      .then((canvas) => {
        const imgData = canvas.toDataURL("image/png");
        const pdf = new jsPDF({
          orientation: "portrait",
          unit: "mm",
          format: "a4",
          putOnlyUsedFonts: true,
          floatPrecision: 16, // Optional: increase precision
        });

        const imgWidth = 210; // A4 width in mm
        const pageHeight = 295; // A4 height in mm
        const imgHeight = (canvas.height * imgWidth) / canvas.width;
        let heightLeft = imgHeight;
        let position = 0;

        // Add image and handle page breaks
        if (heightLeft >= pageHeight) {
          // If the image is larger than one page
          while (heightLeft >= 0) {
            pdf.addImage(imgData, "PNG", 0, position, imgWidth, imgHeight);
            heightLeft -= pageHeight;
            position -= pageHeight;
            if (heightLeft >= 0) {
              pdf.addPage();
            }
          }
        } else {
          // If the image fits on one page
          pdf.addImage(imgData, "PNG", 0, 0, imgWidth, imgHeight);
        }

        pdf.save("reports.pdf");
      })
      .catch((error) => {
        console.error("Error generating PDF:", error);
      });
  };

  // Create a mapping for manager counts from groupedDeals
  const managerCounts = {};
  const vehicleCountsByManager = {};
  const menuCountsByManager = {};
  if (data.groupedDeals) {
    data.groupedDeals.forEach((deal) => {
      managerCounts[deal.sales_manager] = deal.managerCount || 0;
      vehicleCountsByManager[deal.sales_manager] = deal.vehicleCounts || {};
      menuCountsByManager[deal.sales_manager] = deal.menuDetails || {};
    });
  }

  return (
    <div>
      <MainWrapper title={"Comparison Reports"} className="">
        <div>
          <button
            onClick={downloadPDF}
            className="btn btn-danger"
            style={{ marginTop: "20px", color: "white", background: "red" }}
          >
            Download PDF
          </button>
        </div>
        <div id="pdf-content">
          <div className="text-dark m-5 p-5">
            <h1 className="text-center font-weight-bold">COMPARISON REPORTS</h1>

            <h1 className="font-weight-bold">
              F&I Comparison
              <br />
              Fairfield Group
            </h1>
            <p className="h5">
              Date Range : 10/1/2024 to 10/31/2024
              <br />
              Filtered On Type(s) : Retail, Fleet
              <br />
              Filtered on Status : Sold, Closed
              <br />
              Including Class : New, Used and Trxn : Finance, Lease, Cash,
              Balloon and Products : Back
            </p>

            <table
              border="1"
              cellPadding="10"
              cellSpacing="0"
              style={{
                width: "100%",
                borderCollapse: "collapse",
                marginTop: "2%",
              }}
            >
              <thead>
                <tr>
                  <th></th>
                  <th>BackEnd PVR</th>
                  <th>BackEnd Gross</th>
                  <th>PVR Finance</th>
                  <th>PVR Lease</th>
                  <th>PVRCash</th>
                  <th>AvgRate Spread</th>
                  <th>Total Units</th>
                  <th>Prod Avg Per Unit</th>
                  <th>Menus Printed</th>
                  <th>Disclos. Printed</th>
                  <th>Finance Units</th>
                  <th>Finance Units %</th>
                  <th>VSC</th>
                  <th>VSC %</th>
                  <th>GAP</th>
                  <th>GAP %</th>
                  <th>ROAD HAZ.</th>
                </tr>
              </thead>
              <tbody>
                {manager.map((item, index) => {
                  const managerId = item.id; // Assuming `id` is the unique identifier for each manager
                  const managerCount = managerCounts[managerId] || 0;
                  const vehicleCount = vehicleCountsByManager[managerId] || {};
                  const menuCount = menuCountsByManager[managerId] || {};
                  console.log("menuCount", managerCount);
                  return (
                    <tr key={index}>
                      <td>{item.name}</td>
                      <td>
                        $
                        {(
                          menuCount.totalProfitForManager / managerCount
                        ).toFixed(2)}
                      </td>
                      <td>
                        $
                        {(
                          menuCount.totalProfitForManager +
                          menuCount.totalInterestForManager
                        ).toFixed(2)}
                      </td>

                      <td>
                        ${vehicleCount ? vehicleCount.financedAmount : "0"}
                      </td>
                      <td>${vehicleCount ? vehicleCount.leasedAmount : "0"}</td>
                      <td>${vehicleCount ? vehicleCount.cashAmount : "0"}</td>
                      <td>
                        {(
                          (menuCount.totalProfitForManager +
                            menuCount.totalInterestForManager) /
                          managerCount
                        ).toFixed(2)}
                      </td>
                      <td>{managerCount ? managerCount : "0"}</td>
                      <td>{menuCount ? menuCount.prodAvgPerUnit : "0"}</td>
                      <td>0</td>
                      <td>0</td>
                      <td>{vehicleCount ? vehicleCount.Financed : "0"}</td>
                      <td>
                        {(
                          (vehicleCount.Financed / managerCount) * 100 || 0
                        ).toFixed(2)}
                      </td>
                      <td>{menuCount ? menuCount.vscCount : "0"}</td>
                      <td>
                        {(
                          (menuCount.vscCount / managerCount) * 100 || 0
                        ).toFixed(2)}
                      </td>
                      <td>{menuCount ? menuCount.gapCount : "0"}</td>
                      <td>
                        {(
                          (menuCount.gapCount / managerCount) * 100 || 0
                        ).toFixed(2)}
                      </td>
                      <td>{vehicleCount ? vehicleCount.roadHaz : "0"}</td>
                    </tr>
                  );
                })}
                <tr>
                  <td>TOTAL</td>
                  {/* You can sum up the totals if needed */}
                  <td>{/* Total totalProfitForManager */}</td>
                  <td>{/* Total totalProfitForManager */}</td>
                  <td>{/* Total Financed */}</td>
                  <td>{/* Total Leased */}</td>
                  <td>{/* Total PVRCash */}</td>
                  <td>{/* Total avgRateSpread */}</td>
                  <td>{/* Total totalUnits */}</td>
                  <td>{/* Total prodAvgPerUnit */}</td>
                  <td>{/* Total menusPrinted */}</td>
                  <td>{/* Total disclosPrinted */}</td>
                  <td>{/* Total financeUnits */}</td>
                  <td>{/* Total financeUnitsPercentage */}</td>
                  <td>{/* Total vsc */}</td>
                  <td>{/* Total vscPercentage */}</td>
                  <td>{/* Total gap */}</td>
                  <td>{/* Total gapPercentage */}</td>
                  <td>{/* Total roadHaz */}</td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      </MainWrapper>
    </div>
  );
};

export default Comparison;
