import React from "react";
import MainWrapper from "../Main/MainWrapper";
import { useState } from "react";
import { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import Form from "../Common/Form";
import { getCookie } from "../../utils";
import axios from "axios";

const AddManager = () => {
  const navigate = useNavigate();
  const [dealers, setDealers] = useState([]);
  const loginedUser = JSON.parse(getCookie("loginData"));
  // Fetch managers and dealers from API on component mount
  useEffect(() => {
    const fetchManagersAndDealers = async () => {
      try {
        const dealerResponse = await axios.get(
          "https://api.menuverse.ai/dealers/show",
          {
            headers: {
              "Content-Type": "application/json",
              authorization: `Bearer ${loginedUser.data.token}`,
            },
          }
        );

        setDealers(
          dealerResponse.data.data.map((dealer) => ({
            name: dealer.name,
            id: dealer.id,
          }))
        );
      } catch (error) {
        console.error("Failed to fetch managers or dealers:", error);
      }
    };

    fetchManagersAndDealers();
  }, [loginedUser.data.token]);

  const cities = [
    { name: "Amritsar", id: 1 },
    { name: "Jalandhar", id: 2 },
    { name: "Ludhiana", id: 3 },
  ];
  const states = [
    { name: "Delhi", id: 1 },
    { name: "Punjab", id: 2 },
    { name: "Haryana", id: 3 },
  ];

  const formFields = [
    {
      fieldName: "firm_name",
      fieldType: "text",
      fieldMask: "",
      fieldMaxLength: "100",
      fieldRequired: "true",
      fieldDefaultValue: "",
      fieldLabel: "Name of the Firm?",
    },
    {
      fieldName: "email",
      fieldType: "text",
      fieldMask: "",
      fieldMaxLength: "100",
      fieldRequired: "true",
      fieldDefaultValue: "",
      fieldLabel: "Email Address",
    },
    {
      fieldName: "password",
      fieldType: "password",
      fieldMask: "",
      fieldMaxLength: "100",
      fieldRequired: "true",
      fieldDefaultValue: "",
      fieldLabel: "Password",
    },
    {
      fieldName: "dba",
      fieldType: "hidden",
      fieldMask: "",
      fieldMaxLength: "100",
      fieldDefaultValue: "",
    },
    {
      fieldName: "tax_id",
      fieldType: "hidden",
      fieldMask: "",
      fieldMaxLength: "100",
      fieldDefaultValue: "",
    },
    {
      fieldName: "zip",
      fieldType: "text",
      fieldMask: "",
      fieldMaxLength: "100",
      fieldRequired: "true",
      fieldDefaultValue: "",
      fieldLabel: "Zip Code",
    },
    {
      fieldName: "phone",
      fieldType: "text",
      fieldMask: "",
      fieldMaxLength: "100",
      fieldRequired: "true",
      fieldDefaultValue: "",
      fieldLabel: "Owner's Contact Number",
    },
    {
      fieldName: "fl_number",
      fieldType: "hidden",
      fieldMask: "",
      fieldMaxLength: "100",

      fieldDefaultValue: "",
    },
    {
      fieldName: "agreement_effective_date",
      fieldType: "date",
      fieldMask: "",
      fieldMaxLength: "100",
      fieldRequired: "true",
      fieldDefaultValue: "",
      fieldLabel: "Agreement Date",
    },
    {
      fieldName: "kick_off_date",
      fieldType: "date",
      fieldMask: "",
      fieldMaxLength: "100",
      fieldRequired: "true",
      fieldDefaultValue: "",
      fieldLabel: "Kick Off Date",
    },
    {
      fieldName: "dealerId",
      fieldType: "select",
      fieldOptions: dealers,
      fieldLabel: "Dealer",
    },
    // {
    //   fieldName: "will_manager_mirror",
    //   fieldType: "radio",
    //   fieldMask: "",
    //   fieldMaxLength: "100",
    //   fieldRequired: "true",
    //   fieldDefaultValue: "",
    //   fieldValue: "yes",
    //   fieldLabel: "Will this manager mirror another manager's setup?",
    // },
    // {
    //   fieldName: "will_manager_mirror",
    //   fieldType: "radio",
    //   fieldMask: "",
    //   fieldMaxLength: "100",
    //   fieldRequired: "true",
    //   fieldDefaultValue: "",
    //   fieldValue: "no",
    //   fieldLabel: "Will this manager mirror another manager's setup?",
    // },
    {
      fieldName: "city",
      fieldType: "select",
      fieldMask: "",
      fieldMaxLength: "",
      fieldRequired: "true",
      fieldDefaultValue: "",
      fieldValue: "",
      fieldOptions: cities,
      dbType: "integer",
      fieldLabel: "City",
    },
    {
      fieldName: "state",
      fieldType: "select",
      fieldMask: "",
      fieldMaxLength: "",
      fieldRequired: "true",
      fieldDefaultValue: "",
      fieldValue: "",
      fieldOptions: states,
      dbType: "integer",
      fieldLabel: "State",
    },
    {
      fieldName: "address",
      fieldType: "textarea",
      fieldMask: "",
      fieldMaxLength: "",
      fieldRequired: "true",
      fieldDefaultValue: "",
      fieldValue: "",
      fieldLabel: "Address",
    },
  ];

  const formDefinition = {
    type: "add",
    URL: "https://api.menuverse.ai/managers/create",
    successRedirect: "/managers",
  };

  return (
    <>
      <MainWrapper title="Add Manager">
        <div className="card card-default" bis_skin_checked="1">
          <div className="card-header" bis_skin_checked="1">
            <h2></h2>
          </div>
          <div className="card-body" bis_skin_checked="1">
            <div
              className="row"
              bis_skin_checked="1"
              style={{
                border: "1px solid #eee",
                paddingTop: "20px",
                paddingBottom: "20px",
              }}
            >
              <div className="col-xl-12">
                <Form formFields={formFields} {...formDefinition} />
              </div>
            </div>
          </div>
        </div>
      </MainWrapper>
    </>
  );
};

export default AddManager;
