import React from "react";
import MainWrapper from "../Main/MainWrapper";
import { useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { useEffect } from "react";
import Form from "../Common/Form";
import { getCookie } from "../../utils";
import axios from "axios";

const EditNewDeals = () => {
  const loginedUser = JSON.parse(getCookie("loginData"));
  const navigate = useNavigate();

  const { id } = useParams();
  const [states, setStates] = useState([]);
  const [cities, setCities] = useState([]);
  const [dbTaxData, setdbTaxData] = useState("");
  const [dbFeesData, setdbFeesData] = useState("");
  const [dbTradeinData, setdbTradeInData] = useState("");
  const [dealers, setDealers] = useState([]);

  const [managers, setManagers] = useState([]);
  const [salesperson, setSalesperson] = useState([]);
  // Fetch managers and dealers from API on component mount
  useEffect(() => {
    const fetchManagersAndSalesperson = async () => {
      try {
        const managerResponse = await axios.get(
          "https://api.menuverse.ai/managers/show",
          {
            headers: {
              "Content-Type": "application/json",
              authorization: `Bearer ${loginedUser.data.token}`,
            },
          }
        );

        const dealerResponse = await axios.get(
          "https://api.menuverse.ai/salespersons/show",
          {
            headers: {
              "Content-Type": "application/json",
              authorization: `Bearer ${loginedUser.data.token}`,
            },
          }
        );

        setManagers(
          managerResponse.data.data.map((manager) => ({
            name: manager.name,
            id: manager.id,
          }))
        );
        setSalesperson(
          dealerResponse.data.data.map((dealer) => ({
            name: dealer.name,
            id: dealer.id,
          }))
        );

        console.log(
          "managers",
          managerResponse.data.data.map((manager) => ({
            name: manager.name,
            id: manager.id,
          }))
        );
        console.log("managers2", managers);
      } catch (error) {
        console.error("Failed to fetch managers or dealers:", error);
      }
    };

    fetchManagersAndSalesperson();
  }, [loginedUser.data.token]);

  const languages = [
    { id: "English (United States)", name: "English (United States)" },
    { id: "English (Canada)", name: "English (Canada)" },
    { id: "Espanol (Maxico)", name: "Espanol (Maxico)" },
    { id: "Francais (Canada)", name: "Francais (Canada)" },
  ];

  const getDealers = async () => {
    const repsonseData = await fetch("https://api.menuverse.ai/dealers/show", {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        authorization: "Bearer " + loginedUser.data.token,
      },
    });

    const response = await repsonseData.json();

    // console.log("Dealers", response);

    setDealers(response.data);
  };
  const statesData = async () => {
    try {
      const response = await fetch("https://api.menuverse.ai/states/show", {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          authorization: "Bearer " + loginedUser.data.token,
        },
      });

      if (response.status === 201 || response.status === 200) {
      } else if (response.status === 500) {
        const result = await response.json();
        window.toastr.error(result.message);
      } else if (response.status === 401 || response.status === 403) {
        navigate("/login");
      }

      const responseData = await response.json();

      setStates(responseData.data);
    } catch (error) {
      console.log("Error Occured", error);
    }
  };

  const ctiesData = async () => {
    try {
      const response = await fetch("https://api.menuverse.ai/cities/show", {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          authorization: "Bearer " + loginedUser.data.token,
        },
      });

      if (response.status === 201 || response.status === 200) {
      } else if (response.status === 500) {
        const result = await response.json();
        window.toastr.error(result.message);
      } else if (response.status === 401 || response.status === 403) {
        navigate("/login");
      }

      const responseData = await response.json();

      setCities(responseData.data);
    } catch (error) {
      console.log("Error Occured", error);
    }
  };

  useEffect(() => {
    statesData();
    getDealers();
    ctiesData();
  }, []);

  const taxoptionTitle = "Tax";
  const feesoptionTitle = "Fee";
  const feesTradeinTitle = "Trade In";

  const [formStructure, setFormStructure] = useState([]);
  const [formCustomerStructure, setCustomerFormStructure] = useState([]);
  const [formCoBuyerStructure, setCoBuyerFormStructure] = useState([]);
  const [formVehicleStructure, setVehicleFormStructure] = useState([]);
  const [formTaxesStructure, setTaxesFormStructure] = useState([]);
  const [formFeesStructure, setFeesFormStructure] = useState([]);
  const [formLenderStructure, setLenderFormStructure] = useState([]);
  const [formTradeStructure, setTradeFormStructure] = useState([]);

  useEffect(() => {
    const formFields = [
      {
        fieldName: "dealer_id",
        fieldType: "select",
        fieldMask: "",
        fieldMaxLength: "",
        fieldRequired: "true",
        fieldDefaultValue: "",
        fieldValue: "",
        fieldOptions: dealers,
        dbType: "integer",
        fieldLabel: "Dealer",
      },
      {
        fieldName: "deal_number",
        fieldType: "number",
        fieldMask: "",
        fieldMaxLength: "100",
        fieldDefaultValue: "",
        fieldLabel: "Deal Number",
      },
      {
        fieldName: "deal_type",
        fieldType: "select",
        fieldMask: "",
        fieldMaxLength: "",
        fieldDefaultValue: "",
        fieldLabel: "Deal Type",
        fieldOptions: [
          { id: "Balloon", name: "Balloon" },
          { id: "Cash", name: "Cash" },
          { id: "Finance", name: "Finance" },
          { id: "Lease", name: "Lease" },
          { id: "Lease APR", name: "Lease APR" },
        ],
        dbType: "string",
      },
      {
        fieldName: "firstName",
        fieldType: "text",
        fieldMask: "",
        fieldMaxLength: "100",
        fieldRequired: "true",
        fieldDefaultValue: "",
        fieldLabel: "First Name",
      },
      {
        fieldName: "middleName",
        fieldType: "text",
        fieldMask: "",
        fieldMaxLength: "100",
        fieldDefaultValue: "",
        fieldLabel: "Middle Name",
      },
      {
        fieldName: "lastName",
        fieldType: "text",
        fieldMask: "",
        fieldMaxLength: "100",
        fieldRequired: "true",
        fieldDefaultValue: "",
        fieldLabel: "Last Name",
      },
      {
        fieldName: "email",
        fieldType: "text",
        fieldMask: "",
        fieldMaxLength: "100",
        fieldDefaultValue: "",
        fieldLabel: "Email Address",
      },

      {
        fieldName: "vinNumber",
        fieldType: "text",
        fieldMask: "",
        fieldMaxLength: "100",
        fieldRequired: "true",
        fieldDefaultValue: "",
        fieldLabel: "VIN Number",
      },

      {
        fieldName: "phoneNumber",
        fieldType: "text",
        fieldMask: "",
        fieldMaxLength: "100",
        fieldDefaultValue: "",
        fieldLabel: "Phone",
      },
      {
        fieldName: "streetAddress",
        fieldType: "textarea",
        fieldMask: "",
        fieldMaxLength: "",
        fieldDefaultValue: "",
        fieldValue: "",
        fieldLabel: "Address",
      },
      {
        fieldName: "city",
        fieldType: "text",
        fieldMask: "",
        fieldMaxLength: "",
        fieldDefaultValue: "",
        fieldValue: "",
        // fieldOptions: cities,
        // dbType: "intege/r",
        fieldLabel: "City",
      },
      {
        fieldName: "state",
        fieldType: "select",
        fieldMask: "",
        fieldMaxLength: "",
        fieldDefaultValue: "",
        fieldValue: "",
        fieldOptions: states,
        dbType: "integer",
        fieldLabel: "State",
      },

      {
        fieldName: "zipCode",
        fieldType: "text",
        fieldMask: "",
        fieldMaxLength: "100",
        fieldDefaultValue: "",
        fieldLabel: "Zip Code",
      },
      {
        fieldName: "vehicle",
        fieldType: "text",
        fieldMask: "",
        fieldMaxLength: "100",
        fieldRequired: "true",
        fieldDefaultValue: "",
        fieldLabel: "Vehicle",
      },
      {
        fieldName: "vehicleColor",
        fieldType: "text",
        fieldMask: "",
        fieldMaxLength: "100",
        fieldDefaultValue: "",
        fieldLabel: "Vehicle Color",
      },
      {
        fieldName: "lender_name",
        fieldType: "text",
        fieldMask: "",
        fieldMaxLength: "100",
        fieldDefaultValue: "",
        fieldLabel: "Lender",
      },
      {
        fieldName: "tax_group",
        fieldType: "select",
        fieldMask: "",
        fieldMaxLength: "100",
        fieldDefaultValue: "",
        fieldLabel: "Tax Group",
        fieldOptions: [
          { id: "CA - Tax Group", name: "CA - Tax Group" },
          { id: "More", name: "More" },
        ],
        dbType: "string",
      },
      {
        fieldName: "free_add_on",
        fieldType: "number",
        fieldMask: "",
        fieldMaxLength: "100",
        fieldDefaultValue: "",
        fieldLabel: "Free Add On",
      },
      {
        fieldName: "taxes",
        fieldType: "number",
        fieldMask: "",
        fieldMaxLength: "100",
        fieldDefaultValue: "",
        fieldLabel: "Taxes",
      },
      {
        fieldName: "fees",
        fieldType: "number",
        fieldMask: "",
        fieldMaxLength: "100",
        fieldDefaultValue: "",
        fieldLabel: "Fees",
      },

      {
        fieldName: "loan_term",
        fieldType: "number",
        fieldMask: "",
        fieldMaxLength: "100",
        fieldDefaultValue: "",
        fieldLabel: "Loan Term",
      },
      {
        fieldName: "purchase_price",
        fieldType: "number",
        fieldMask: "",
        fieldMaxLength: "100",
        fieldDefaultValue: "",
        fieldLabel: "Purchase Price",
      },
      {
        fieldName: "interest_rate",
        fieldType: "number",
        fieldMask: "",
        fieldMaxLength: "100",
        fieldDefaultValue: "",
        fieldLabel: "Interest Rate",
      },
      {
        fieldName: "down_payment",
        fieldType: "number",
        fieldMask: "",
        fieldMaxLength: "100",
        fieldDefaultValue: "",
        fieldLabel: "Down Payment",
      },
      {
        fieldName: "vehicle_payment",
        fieldType: "number",
        fieldMask: "",
        fieldMaxLength: "100",
        fieldDefaultValue: "",
        fieldLabel: "Vehicle Payment",
      },
      {
        fieldName: "net_trade",
        fieldType: "number",
        fieldMask: "",
        fieldMaxLength: "100",
        fieldDefaultValue: "",
        fieldLabel: "Net Trade",
      },
      {
        fieldName: "protection_items",
        fieldType: "number",
        fieldMask: "",
        fieldMaxLength: "100",
        fieldDefaultValue: "",
        fieldLabel: "Protection Items",
      },
      {
        fieldName: "rebate",
        fieldType: "number",
        fieldMask: "",
        fieldMaxLength: "100",
        fieldDefaultValue: "",
        fieldLabel: "Rebate",
      },
      {
        fieldName: "total_monthly_payment",
        fieldType: "number",
        fieldMask: "",
        fieldMaxLength: "100",
        fieldDefaultValue: "",
        fieldLabel: "Total Monthly Payment",
      },

      {
        fieldName: "msrp",
        fieldType: "number",
        fieldMask: "",
        fieldMaxLength: "100",
        fieldDefaultValue: "",
        fieldLabel: "MSRP",
      },
      {
        fieldName: "sales_person",
        fieldType: "select",
        fieldOptions: salesperson,
        fieldLabel: "Sales Person",
      },
      {
        fieldName: "sales_person2",
        fieldType: "select",
        fieldOptions: salesperson,
        fieldLabel: "Sales Person 2",
      },
      {
        fieldName: "business_manager",
        fieldType: "select",
        fieldOptions: managers,
        fieldLabel: "Business Manager",
      },
      {
        fieldName: "sales_manager",
        fieldType: "select",
        fieldOptions: managers,
        fieldLabel: "Sales Manager",
      },
      {
        fieldName: "amount_financed",
        fieldType: "number",
        fieldMask: "",
        fieldMaxLength: "100",
        fieldDefaultValue: "",
        fieldLabel: "Amount Financed",
      },
      {
        fieldName: "max_approved",
        fieldType: "number",
        fieldMask: "",
        fieldMaxLength: "100",
        fieldDefaultValue: "",
        fieldLabel: "Max Approved",
      },
    ];

    const formCustomerFields = [
      {
        fieldName: "first_name",
        fieldType: "text",
        fieldMask: "",
        fieldMaxLength: "",
        fieldRequired: "true",
        fieldDefaultValue: "",
        fieldValue: "",
        // fieldOptions: dealers,
        // dbType: "integer",
        fieldLabel: "First Name",
      },
      {
        fieldName: "middle_name",
        fieldType: "text",
        fieldMask: "",
        fieldMaxLength: "100",
        fieldDefaultValue: "",
        fieldLabel: "Middle Name",
      },
      {
        fieldName: "last_name",
        fieldType: "text",
        fieldMask: "",
        fieldMaxLength: "100",
        fieldRequired: "true",
        fieldDefaultValue: "",
        fieldLabel: "Last Name",
      },
      {
        fieldName: "country_id",
        fieldType: "select",
        fieldMask: "",
        fieldMaxLength: "",
        fieldRequired: "true",
        fieldDefaultValue: "",
        fieldValue: "",
        fieldOptions: [{ id: 1, name: "United States" }],
        dbType: "integer",
        fieldLabel: "Country",
      },

      {
        fieldName: "address1",
        fieldType: "text",
        fieldMask: "",
        fieldMaxLength: "100",
        fieldRequired: "true",
        fieldDefaultValue: "",
        fieldLabel: "Address Line 1",
      },
      {
        fieldName: "address2",
        fieldType: "text",
        fieldMask: "",
        fieldMaxLength: "100",
        fieldDefaultValue: "",
        fieldLabel: "Address Line 2",
      },

      {
        fieldName: "city_id",
        fieldType: "text",
        fieldMask: "",
        fieldMaxLength: "",
        fieldRequired: "true",
        fieldDefaultValue: "",
        fieldValue: "",
        // fieldOptions: cities,
        // dbType: "integer",
        fieldLabel: "City",
      },
      {
        fieldName: "state_id",
        fieldType: "select",
        fieldMask: "",
        fieldMaxLength: "",
        fieldRequired: "true",
        fieldDefaultValue: "",
        fieldValue: "",
        fieldOptions: states,
        dbType: "integer",
        fieldLabel: "State",
      },

      {
        fieldName: "zip_code",
        fieldType: "text",
        fieldMask: "",
        fieldMaxLength: "100",
        fieldRequired: "true",
        fieldDefaultValue: "",
        fieldLabel: "Zip Code",
      },
      {
        fieldName: "language",
        fieldType: "select",
        fieldMask: "",
        fieldMaxLength: "100",
        fieldDefaultValue: "",
        fieldLabel: "Language",
        fieldOptions: languages,
        dbType: "string",
      },
      {
        fieldName: "dob",
        fieldType: "date",
        fieldMask: "",
        fieldMaxLength: "100",
        fieldRequired: "true",
        fieldDefaultValue: "",
        fieldLabel: "Birth Date",
      },
      {
        fieldName: "home_phone",
        fieldType: "text",
        fieldMask: "",
        fieldMaxLength: "15",
        fieldRequired: "true",
        fieldDefaultValue: "",
        fieldLabel: "Home Phone",
      },
      {
        fieldName: "work_phone",
        fieldType: "text",
        fieldMask: "",
        fieldMaxLength: "15",
        fieldDefaultValue: "",
        fieldLabel: "Work Phone",
      },
      {
        fieldName: "email",
        fieldType: "text",
        fieldMask: "",
        fieldMaxLength: "200",
        fieldDefaultValue: "",
        fieldLabel: "Email Address",
      },

      {
        fieldName: "is_consent_received",
        fieldType: "checkbox",
        fieldMask: "",
        fieldMaxLength: "100",
        fieldDefaultValue: "",
        fieldValue: "0",
        fieldLabel:
          "I have received customer's consent to send them SMS or Email MessagesLoan Term",
      },
      {
        fieldName: "is_mail",
        fieldType: "checkbox",
        fieldMask: "",
        fieldMaxLength: "100",
        fieldDefaultValue: "",
        fieldLabel: "Email",
        fieldValue: "0",
      },
      {
        fieldName: "is_phone",
        fieldType: "checkbox",
        fieldMask: "",
        fieldMaxLength: "100",
        fieldDefaultValue: "",
        fieldLabel: "Phone",
        fieldValue: "0",
      },
    ];
    const formCoBuyerFields = [
      {
        fieldName: "first_name",
        fieldType: "text",
        fieldMask: "",
        fieldMaxLength: "",
        fieldRequired: "true",
        fieldDefaultValue: "",
        fieldValue: "",
        // fieldOptions: dealers,
        // dbType: "integer",
        fieldLabel: "First Name",
      },
      {
        fieldName: "middle_name",
        fieldType: "text",
        fieldMask: "",
        fieldMaxLength: "100",
        fieldDefaultValue: "",
        fieldLabel: "Middle Name",
      },
      {
        fieldName: "last_name",
        fieldType: "text",
        fieldMask: "",
        fieldMaxLength: "100",
        fieldRequired: "true",
        fieldDefaultValue: "",
        fieldLabel: "Last Name",
      },
      {
        fieldName: "country_id",
        fieldType: "select",
        fieldMask: "",
        fieldMaxLength: "",
        fieldRequired: "true",
        fieldDefaultValue: "",
        fieldValue: "",
        fieldOptions: [{ id: 1, name: "United States" }],
        dbType: "integer",
        fieldLabel: "Country",
      },

      {
        fieldName: "address1",
        fieldType: "text",
        fieldMask: "",
        fieldMaxLength: "100",
        fieldRequired: "true",
        fieldDefaultValue: "",
        fieldLabel: "Address Line 1",
      },
      {
        fieldName: "address2",
        fieldType: "text",
        fieldMask: "",
        fieldMaxLength: "100",
        fieldDefaultValue: "",
        fieldLabel: "Address Line 2",
      },

      {
        fieldName: "city_id",
        fieldType: "text",
        fieldMask: "",
        fieldMaxLength: "",
        fieldRequired: "true",
        fieldDefaultValue: "",
        fieldValue: "",
        // fieldOptions: cities,
        // dbType: "integer",
        fieldLabel: "City",
      },
      {
        fieldName: "state_id",
        fieldType: "select",
        fieldMask: "",
        fieldMaxLength: "",
        fieldRequired: "true",
        fieldDefaultValue: "",
        fieldValue: "",
        fieldOptions: states,
        dbType: "integer",
        fieldLabel: "State",
      },

      {
        fieldName: "zip_code",
        fieldType: "text",
        fieldMask: "",
        fieldMaxLength: "100",
        fieldRequired: "true",
        fieldDefaultValue: "",
        fieldLabel: "Zip Code",
      },

      {
        fieldName: "dob",
        fieldType: "date",
        fieldMask: "",
        fieldMaxLength: "100",
        fieldRequired: "true",
        fieldDefaultValue: "",
        fieldLabel: "Birth Date",
      },
      {
        fieldName: "home_phone",
        fieldType: "text",
        fieldMask: "",
        fieldMaxLength: "15",
        fieldRequired: "true",
        fieldDefaultValue: "",
        fieldLabel: "Home Phone",
      },
    ];

    const formVehicleFields = [
      {
        fieldName: "msrp",
        fieldType: "text",
        fieldMask: "",
        fieldMaxLength: "",
        fieldRequired: "true",
        fieldDefaultValue: "",
        fieldValue: "",
        // fieldOptions: dealers,
        // dbType: "integer",
        fieldLabel: "MSRP",
      },
      {
        fieldName: "odometer",
        fieldType: "number",
        fieldMask: "",
        fieldMaxLength: "100",
        fieldDefaultValue: "",
        fieldLabel: "Odometer",
      },
      {
        fieldName: "stock_number",
        fieldType: "text",
        fieldMask: "",
        fieldMaxLength: "100",
        fieldRequired: "true",
        fieldDefaultValue: "",
        fieldLabel: "Stock #",
      },
      {
        fieldName: "in_service_date",
        fieldType: "date",
        fieldMask: "",
        fieldMaxLength: "",
        fieldRequired: "true",
        fieldDefaultValue: "",
        fieldValue: "",
        // fieldOptions: [{ id: 1, name: "United States" }],
        // dbType: "integer",
        fieldLabel: "In-Service Date",
      },

      {
        fieldName: "vin",
        fieldType: "text",
        fieldMask: "",
        fieldMaxLength: "100",
        fieldRequired: "true",
        fieldDefaultValue: "",
        fieldLabel: "VIN",
      },
      {
        fieldName: "year",
        fieldType: "text",
        fieldMask: "",
        fieldMaxLength: "100",
        fieldRequired: "true",
        fieldDefaultValue: "",
        fieldLabel: "Year",
      },

      {
        fieldName: "make",
        fieldType: "text",
        fieldMask: "",
        fieldMaxLength: "",
        fieldRequired: "true",
        fieldDefaultValue: "",
        fieldValue: "",
        // fieldOptions: cities,
        // dbType: "integer",
        fieldLabel: "Make",
      },

      {
        fieldName: "model",
        fieldType: "text",
        fieldMask: "",
        fieldMaxLength: "100",
        fieldRequired: "true",
        fieldDefaultValue: "",
        fieldLabel: "Model",
      },
      {
        fieldName: "v_class",
        fieldType: "select",
        fieldMask: "",
        fieldMaxLength: "100",
        fieldDefaultValue: "",
        fieldLabel: "Class",
        fieldOptions: [
          { id: "Certified Pre-Owned", name: "Certified Pre-Owned" },
          { id: "Demo", name: "Demo" },
          { id: "New", name: "New" },
          { id: "Unknown", name: "Unknown" },
          { id: "Used", name: "Used" },
          { id: "Financed", name: "Financed" },
          { id: "Leased", name: "Leased" },
          { id: "Cash", name: "Cash" },
        ],
        dbType: "string",
      },
    ];

    const formTaxesFields = [
      {
        fieldName: "ranges",
        fieldType: "multiples",
        fieldMask: "",
        fieldMaxLength: "100",
        fieldRequired: "true",
        fieldDefaultValue: "",
        multiples: [
          {
            fieldName: "name",
            fieldType: "text",
            fieldMask: "",
            fieldMaxLength: "",
            fieldRequired: "true",
            fieldDefaultValue: "",
            fieldValue: "",
            // fieldOptions: dealers,
            // dbType: "integer",
            fieldLabel: "Tax name",
          },
          {
            fieldName: "is_gst",
            fieldType: "checkbox",
            fieldMask: "",
            fieldMaxLength: "",
            fieldDefaultValue: "",
            fieldValue: "",
            // fieldOptions: cities,
            // dbType: "integer",
            fieldLabel: "GST",
          },
          {
            fieldName: "type",
            fieldType: "radio",
            fieldMask: "",
            fieldMaxLength: "100",
            fieldRequired: "true",
            fieldValue: "Percentage",
            fieldDefaultValue: "",
            fieldLabel: "Tax Type",
          },
          {
            fieldName: "type",
            fieldType: "radio",
            fieldMask: "",
            fieldMaxLength: "100",
            fieldRequired: "true",
            fieldValue: "Flat Amount",
            fieldDefaultValue: "",
            fieldLabel: "Tax Type",
          },
          {
            fieldName: "rate",
            fieldType: "text",
            fieldMask: "",
            fieldMaxLength: "100",
            fieldRequired: "true",
            fieldDefaultValue: "",
            fieldLabel: "Rate",
          },

          {
            fieldName: "tax_base",
            fieldType: "text",
            fieldMask: "",
            fieldMaxLength: "",
            fieldRequired: "true",
            fieldDefaultValue: "",
            fieldValue: "",
            // fieldOptions: [{ id: 1, name: "United States" }],
            // dbType: "integer",
            fieldLabel: "Tax Base",
          },

          {
            fieldName: "base_uses",
            fieldType: "radio",
            fieldMask: "",
            fieldMaxLength: "100",
            fieldRequired: "true",
            fieldValue: "Vehicle Sale Price",
            fieldDefaultValue: "",
            fieldLabel: "Base Uses",
          },
          {
            fieldName: "base_uses",
            fieldType: "radio",
            fieldMask: "",
            fieldMaxLength: "100",
            fieldRequired: "true",
            fieldValue: "Fair Market Value",
            fieldDefaultValue: "",
            fieldLabel: "Vase Uses",
          },
          {
            fieldName: "base_uses",
            fieldType: "radio",
            fieldMask: "",
            fieldMaxLength: "100",
            fieldRequired: "true",
            fieldValue: "90% of MSRP",
            fieldDefaultValue: "",
            fieldLabel: "Vase Uses",
          },
          {
            fieldName: "base_uses",
            fieldType: "radio",
            fieldMask: "",
            fieldMaxLength: "100",
            fieldRequired: "true",
            fieldValue: "Lesser of Vehicle Sale Price or 90% of MSRP",
            fieldDefaultValue: "",
            fieldLabel: "Vase Uses",
          },
          {
            fieldName: "less_rebate",
            fieldType: "checkbox",
            fieldMask: "",
            fieldMaxLength: "100",
            fieldDefaultValue: "",
            fieldValue: "1",
            fieldLabel: "Rebate (Less)",
          },

          {
            fieldName: "less_cash",
            fieldType: "checkbox",
            fieldMask: "",
            fieldMaxLength: "",
            fieldDefaultValue: "",
            fieldValue: "1",
            // fieldOptions: cities,
            // dbType: "integer",
            fieldLabel: "Cash (Less)",
          },
          {
            fieldName: "less_trade",
            fieldType: "checkbox",
            fieldMask: "",
            fieldMaxLength: "",
            fieldDefaultValue: "",
            fieldValue: "1",
            // fieldOptions: states,
            // dbType: "integer",
            fieldLabel: "Trade In 1, 2, n (Less)",
          },

          {
            fieldName: "less_payoff",
            fieldType: "checkbox",
            fieldMask: "",
            fieldMaxLength: "100",
            fieldDefaultValue: "1",
            fieldValue: "1",
            fieldLabel: "Tax Includes Payoff Amount (Less)",
          },
          {
            fieldName: "less_credit",
            fieldType: "checkbox",
            fieldMask: "",
            fieldMaxLength: "100",
            fieldDefaultValue: "1",
            fieldValue: "1",
            fieldLabel: "Tax Credit on first of Trade In (Less)",
          },
        ],
      },
    ];

    const formFeesFields = [
      {
        fieldName: "ranges",
        fieldType: "multiples",
        fieldMask: "",
        fieldMaxLength: "100",
        fieldRequired: "true",
        fieldDefaultValue: "",
        multiples: [
          {
            fieldName: "name",
            fieldType: "text",
            fieldMask: "",
            fieldMaxLength: "",
            fieldRequired: "true",
            fieldDefaultValue: "",
            fieldValue: "",
            // fieldOptions: dealers,
            // dbType: "integer",
            fieldLabel: "Fee name",
          },
          {
            fieldName: "is_flat_amount",
            fieldType: "radio",
            fieldMask: "",
            fieldMaxLength: "100",
            fieldDefaultValue: "Flat Amount",
            fieldValue: "Flat Amount",
            fieldLabel: " ",
          },
          {
            fieldName: "is_flat_amount",
            fieldType: "radio",
            fieldMask: "",
            fieldMaxLength: "100",
            fieldValue: "Percentage",
            fieldDefaultValue: "Flat Amount",
            fieldLabel: "Rate",
          },
          {
            fieldName: "amount",
            fieldType: "text",
            fieldMask: "",
            fieldMaxLength: "",
            fieldRequired: "true",
            fieldDefaultValue: "",
            fieldValue: "",
            // fieldOptions: [{ id: 1, name: "United States" }],
            // dbType: "integer",
            fieldLabel: "Amount",
          },

          {
            fieldName: "is_finance_charge",
            fieldType: "checkbox",
            fieldMask: "",
            fieldMaxLength: "100",
            fieldValue: "1",
            fieldDefaultValue: "",
            fieldLabel: "Finance Charge",
          },
          {
            fieldName: "is_taxable",
            fieldType: "checkbox",
            fieldMask: "",
            fieldMaxLength: "100",
            fieldValue: "1",
            fieldDefaultValue: "",
            fieldLabel: "Taxable",
          },
        ],
      },
    ];
    const formLenderFields = [
      {
        fieldName: "name",
        fieldType: "text",
        fieldMask: "",
        fieldMaxLength: "",
        fieldRequired: "true",
        fieldDefaultValue: "",
        fieldValue: "",
        // fieldOptions: dealers,
        // dbType: "integer",
        fieldLabel: "Lender name",
      },
      {
        fieldName: "country_id",
        fieldType: "select",
        fieldMask: "",
        fieldMaxLength: "100",
        fieldDefaultValue: "",
        fieldValue: "",
        fieldLabel: "Country",
        fieldOptions: [{ id: 1, name: "United States" }],
        dbType: "integer",
      },
      {
        fieldName: "Address1",
        fieldType: "text",
        fieldMask: "",
        fieldMaxLength: "100",
        fieldRequired: "true",
        fieldValue: "",
        fieldDefaultValue: "",
        fieldLabel: "Address Line 1",
      },
      {
        fieldName: "Address2",
        fieldType: "text",
        fieldMask: "",
        fieldMaxLength: "100",
        fieldValue: "",
        fieldDefaultValue: "",
        fieldLabel: "Address Line 2",
      },
      {
        fieldName: "city_id",
        fieldType: "text",
        fieldMask: "",
        fieldMaxLength: "100",
        fieldValue: "",
        fieldDefaultValue: "",
        fieldLabel: "City",
        // fieldOptions: cities,
        // dbType: "integer",
      },
      {
        fieldName: "state_id",
        fieldType: "select",
        fieldMask: "",
        fieldMaxLength: "",
        fieldRequired: "true",
        fieldDefaultValue: "",
        fieldValue: "",
        fieldOptions: states,
        dbType: "integer",
        fieldLabel: "State",
      },

      {
        fieldName: "zipcode",
        fieldType: "text",
        fieldMask: "",
        fieldMaxLength: "100",
        fieldRequired: "true",
        fieldValue: "",
        fieldDefaultValue: "",
        fieldLabel: "Zip Code",
      },
      {
        fieldName: "phone",
        fieldType: "text",
        fieldMask: "",
        fieldMaxLength: "100",
        fieldValue: "",
        fieldDefaultValue: "",
        fieldLabel: "Phone",
      },
      {
        fieldName: "contract_date",
        fieldType: "date",
        fieldMask: "",
        fieldMaxLength: "100",
        fieldRequired: "true",
        fieldValue: "",
        fieldDefaultValue: "",
        fieldLabel: "Contract Date",
      },
      {
        fieldName: "days_to_first_payment",
        fieldType: "text",
        fieldMask: "",
        fieldMaxLength: "100",
        fieldRequired: "true",
        fieldValue: "",
        fieldDefaultValue: "",
        fieldLabel: "Days to First Payment",
      },
      {
        fieldName: "date_of_first_payment",
        fieldType: "date",
        fieldMask: "",
        fieldMaxLength: "100",
        fieldRequired: "true",
        fieldValue: "",
        fieldDefaultValue: "",
        fieldLabel: "Date of First Payment",
      },
      {
        fieldName: "intrest_start_date",
        fieldType: "date",
        fieldMask: "",
        fieldMaxLength: "100",
        fieldRequired: "true",
        fieldValue: "",
        fieldDefaultValue: "",
        fieldLabel: "Interest Start Date",
      },
    ];
    const formTradeFields = [
      {
        fieldName: "ranges",
        fieldType: "multiples",
        fieldMask: "",
        fieldMaxLength: "100",
        fieldRequired: "true",
        fieldDefaultValue: "",
        multiples: [
          {
            fieldName: "vin",
            fieldType: "text",
            fieldMask: "",
            fieldMaxLength: "",
            fieldRequired: "true",
            fieldDefaultValue: "",
            fieldValue: "",
            // fieldOptions: dealers,
            // dbType: "integer",
            fieldLabel: "VIN",
          },

          {
            fieldName: "year",
            fieldType: "text",
            fieldMask: "",
            fieldMaxLength: "",
            fieldRequired: "true",
            fieldDefaultValue: "",
            fieldValue: "",
            // fieldOptions: [{ id: 1, name: "United States" }],
            // dbType: "integer",
            fieldLabel: "Year",
          },
          {
            fieldName: "make",
            fieldType: "text",
            fieldMask: "",
            fieldMaxLength: "",
            fieldRequired: "true",
            fieldDefaultValue: "",
            fieldValue: "",
            // fieldOptions: [{ id: 1, name: "United States" }],
            // dbType: "integer",
            fieldLabel: "Make",
          },
          {
            fieldName: "model",
            fieldType: "text",
            fieldMask: "",
            fieldMaxLength: "",
            fieldRequired: "true",
            fieldDefaultValue: "",
            fieldValue: "",
            // fieldOptions: [{ id: 1, name: "United States" }],
            // dbType: "integer",
            fieldLabel: "Model",
          },
          {
            fieldName: "value",
            fieldType: "text",
            fieldMask: "",
            fieldMaxLength: "",
            fieldRequired: "true",
            fieldDefaultValue: "",
            fieldValue: "",
            // fieldOptions: [{ id: 1, name: "United States" }],
            // dbType: "integer",
            fieldLabel: "Value",
          },
          {
            fieldName: "pay_off",
            fieldType: "text",
            fieldMask: "",
            fieldMaxLength: "",
            fieldRequired: "true",
            fieldDefaultValue: "",
            fieldValue: "",
            // fieldOptions: [{ id: 1, name: "United States" }],
            // dbType: "integer",
            fieldLabel: "Pay-off",
          },
        ],
      },
    ];

    setCustomerFormStructure(formCustomerFields);
    setCoBuyerFormStructure(formCoBuyerFields);
    setVehicleFormStructure(formVehicleFields);
    setTaxesFormStructure(formTaxesFields);
    setFeesFormStructure(formFeesFields);
    setLenderFormStructure(formLenderFields);
    setTradeFormStructure(formTradeFields);

    const getData = async () => {
      let record = await fetch("https://api.menuverse.ai/newdeal/show/" + id, {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          authorization: "Bearer " + loginedUser.data.token,
        },
      });

      if (record.status === 500) {
        const result = await record.json();
        window.toastr.error(result.message);
        return;
      } else if (record.status === 401 || record.status === 403) {
        navigate("/login");
        return;
      }

      // Now read the JSON once and store it in a variable
      const result = await record.json();
      const dealerData = result.data;

      setFormStructure(
        formFields.map((field) => {
          return { ...field, fieldDefaultValue: dealerData[field.fieldName] };
        })
      );
    };

    const getBuyerData = async () => {
      let record = await fetch("https://api.menuverse.ai/buyers/show/" + id, {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          authorization: "Bearer " + loginedUser.data.token,
        },
      });

      if (record.status === 201 || record.status === 200) {
      } else if (record.status === 500) {
        const result = await record.json();
        window.toastr.error(result.message);
      } else if (record.status === 401 || record.status === 403) {
        navigate("/login");
      }
      record = await record.json();
      const dealerData = record.data.length > 0 ? record.data[0] : {};

      setCustomerFormStructure(
        formCustomerFields.map((field) => {
          return { ...field, fieldDefaultValue: dealerData[field.fieldName] };
        })
      );
    };

    const getVehicleData = async () => {
      let record = await fetch("https://api.menuverse.ai/vehicles/show/" + id, {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          authorization: "Bearer " + loginedUser.data.token,
        },
      });

      if (record.status === 201 || record.status === 200) {
        record = await record.json();
        const dealerData =
          record.data.length > 0 ? record.data[0] : record.data;

        setVehicleFormStructure(
          formVehicleFields.map((field) => {
            return { ...field, fieldDefaultValue: dealerData[field.fieldName] };
          })
        );
      } else if (record.status === 500) {
        const result = await record.json();
        window.toastr.error(result.message);
      } else if (record.status === 401 || record.status === 403) {
        navigate("/login");
      }
    };

    const getTaxesData = async () => {
      let record = await fetch("https://api.menuverse.ai/taxes/show/" + id, {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          authorization: "Bearer " + loginedUser.data.token,
        },
      });

      if (record.status === 201 || record.status === 200) {
        record = await record.json();
        const dealerData = record.data;

        setdbTaxData({ ranges: dealerData });
        if (dealerData.length > 0) {
          setTaxesFormStructure(
            formTaxesFields.map((field) => {
              return {
                ...field,
                fieldDefaultValue: dealerData[field.fieldName],
              };
            })
          );
        } else {
          const formtaxxxx = formTaxesFields.map((field) => {
            const taxData = field.multiples.reduce((arr, fiel) => {
              arr[fiel.fieldName] = "";
              return arr;
            }, {});

            return taxData;
          });

          setdbTaxData({ ranges: formtaxxxx });
        }
      } else if (record.status === 500) {
        const result = await record.json();
        window.toastr.error(result.message);
      } else if (record.status === 401 || record.status === 403) {
        navigate("/login");
      }
    };

    const getFeesData = async () => {
      let record = await fetch("https://api.menuverse.ai/fees/show/" + id, {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          authorization: "Bearer " + loginedUser.data.token,
        },
      });

      if (record.status === 201 || record.status === 200) {
        record = await record.json();
        const dealerData = record.data;

        if (dealerData.length > 0) {
          setdbFeesData({ ranges: dealerData });
          setFeesFormStructure(
            formFeesFields.map((field) => {
              return {
                ...field,
                fieldDefaultValue: dealerData[field.fieldName],
              };
            })
          );
        } else {
          const formFees = formFeesFields.map((field) => {
            const feesData = field.multiples.reduce((arr, fiel) => {
              arr[fiel.fieldName] = "";
              return arr;
            }, {});

            return feesData;
          });

          setdbFeesData({ ranges: formFees });
        }
      } else if (record.status === 500) {
        const result = await record.json();
        window.toastr.error(result.message);
      } else if (record.status === 401 || record.status === 403) {
        navigate("/login");
      }
    };

    const getLenderData = async () => {
      let record = await fetch("https://api.menuverse.ai/lenders/show/" + id, {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          authorization: "Bearer " + loginedUser.data.token,
        },
      });

      if (record.status === 201 || record.status === 200) {
        record = await record.json();
        const dealerData = record.data;

        setLenderFormStructure(
          formLenderFields.map((field) => {
            return { ...field, fieldDefaultValue: dealerData[field.fieldName] };
          })
        );
      } else if (record.status === 500) {
        const result = await record.json();
        window.toastr.error(result.message);
      } else if (record.status === 401 || record.status === 403) {
        navigate("/login");
      }
    };

    const getTradeinData = async () => {
      let record = await fetch("https://api.menuverse.ai/tradeins/show/" + id, {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          authorization: "Bearer " + loginedUser.data.token,
        },
      });

      if (record.status === 201 || record.status === 200) {
        record = await record.json();
        const dealerData = record.data;

        if (dealerData.length > 0) {
          setdbTradeInData({ ranges: dealerData });
          setTradeFormStructure(
            formTradeFields.map((field) => {
              return {
                ...field,
                fieldDefaultValue: dealerData[field.fieldName],
              };
            })
          );
        } else {
          const formTrade = formTradeFields.map((field) => {
            const tradeData = field.multiples.reduce((arr, fiel) => {
              arr[fiel.fieldName] = "";
              return arr;
            }, {});

            return tradeData;
          });

          setdbTradeInData({ ranges: formTrade });
        }
      } else if (record.status === 500) {
        const result = await record.json();
        window.toastr.error(result.message);
      } else if (record.status === 401 || record.status === 403) {
        navigate("/login");
      }
    };

    getData();

    getBuyerData();

    getVehicleData();

    getTaxesData();

    getFeesData();

    getLenderData();

    getTradeinData();
  }, [states, cities, dealers]);

  const formDefinition = {
    type: "edit",
    URL: "https://api.menuverse.ai/newdeal/update/" + id,
    successRedirect: "",
  };
  const formBuyersDefinition = {
    type: "edit",
    URL: "https://api.menuverse.ai/buyers/update/" + id,
    successRedirect: "",
  };
  const formVehicleDefinition = {
    type: "edit",
    URL: "https://api.menuverse.ai/vehicles/update/" + id,
    successRedirect: "",
  };
  const formTaxDefinition = {
    type: "edit",
    URL: "https://api.menuverse.ai/taxes/update/" + id,
    successRedirect: "",
  };
  const formFeeDefinition = {
    type: "edit",
    URL: "https://api.menuverse.ai/fees/update/" + id,
    successRedirect: "",
  };
  const formLendersDefinition = {
    type: "edit",
    URL: "https://api.menuverse.ai/lenders/update/" + id,
    successRedirect: "",
  };
  const formTradeInsDefinition = {
    type: "edit",
    URL: "https://api.menuverse.ai/tradeins/update/" + id,
    successRedirect: "",
  };

  return (
    <>
      <MainWrapper title="Edit Deal">
        <nav>
          <div class="nav nav-tabs" id="nav-tab" role="tablist">
            <a
              class="nav-item nav-link active"
              id="nav-home-tab"
              data-toggle="tab"
              href="#nav-home"
              role="tab"
              aria-controls="nav-home"
              aria-selected="true"
            >
              Deal
            </a>
            <a
              class="nav-item nav-link"
              id="nav-profile-tab"
              data-toggle="tab"
              href="#nav-profile"
              role="tab"
              aria-controls="nav-profile"
              aria-selected="false"
            >
              Buyer
            </a>
            <a
              class="nav-item nav-link"
              id="nav-cobuyer-tab"
              data-toggle="tab"
              href="#nav-cobuyer"
              role="tab"
              aria-controls="nav-cobuyer"
              aria-selected="false"
            >
              Co-Buyer
            </a>
            <a
              class="nav-item nav-link"
              id="nav-contact-tab"
              data-toggle="tab"
              href="#nav-contact"
              role="tab"
              aria-controls="nav-contact"
              aria-selected="false"
            >
              Vehicle
            </a>
            <a
              class="nav-item nav-link"
              id="nav-taxes-tab"
              data-toggle="tab"
              href="#nav-taxes"
              role="tab"
              aria-controls="nav-taxes"
              aria-selected="false"
            >
              Taxes
            </a>
            <a
              class="nav-item nav-link"
              id="nav-Fees-tab"
              data-toggle="tab"
              href="#nav-Fees"
              role="tab"
              aria-controls="nav-Fees"
              aria-selected="false"
            >
              Fees
            </a>
            <a
              class="nav-item nav-link"
              id="nav-lender-tab"
              data-toggle="tab"
              href="#nav-lender"
              role="tab"
              aria-controls="nav-lender"
              aria-selected="false"
            >
              Lender
            </a>
            <a
              class="nav-item nav-link"
              id="nav-trade-tab"
              data-toggle="tab"
              href="#nav-trade"
              role="tab"
              aria-controls="nav-trade"
              aria-selected="false"
            >
              Trade In
            </a>
          </div>
        </nav>
        <div class="tab-content" id="nav-tabContent">
          <div
            class="tab-pane fade show active"
            id="nav-home"
            role="tabpanel"
            aria-labelledby="nav-home-tab"
          >
            <div className="card card-default" bis_skin_checked="1">
              <div className="card-header" bis_skin_checked="1">
                {/* <h2></h2> */}
              </div>
              <div className="card-body" bis_skin_checked="1">
                <div
                  className="row"
                  bis_skin_checked="1"
                  style={{
                    border: "1px solid #eee",
                    paddingTop: "20px",
                    paddingBottom: "20px",
                  }}
                >
                  <div className="col-xl-12">
                    <Form formFields={formStructure} {...formDefinition} />
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div
            class="tab-pane fade"
            id="nav-profile"
            role="tabpanel"
            aria-labelledby="nav-profile-tab"
          >
            <div className="card card-default" bis_skin_checked="1">
              <div className="card-header" bis_skin_checked="1">
                {/* <h2></h2> */}
              </div>
              <div className="card-body" bis_skin_checked="1">
                <div
                  className="row"
                  bis_skin_checked="1"
                  style={{
                    border: "1px solid #eee",
                    paddingTop: "20px",
                    paddingBottom: "20px",
                  }}
                >
                  <div className="col-xl-12">
                    <Form
                      formFields={formCustomerStructure}
                      {...formBuyersDefinition}
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div
            class="tab-pane fade"
            id="nav-cobuyer"
            role="tabpanel"
            aria-labelledby="nav-cobuyer-tab"
          >
            <div className="card card-default" bis_skin_checked="1">
              <div className="card-header" bis_skin_checked="1">
                {/* <h2></h2> */}
              </div>
              <div className="card-body" bis_skin_checked="1">
                <div
                  className="row"
                  bis_skin_checked="1"
                  style={{
                    border: "1px solid #eee",
                    paddingTop: "20px",
                    paddingBottom: "20px",
                  }}
                >
                  <div className="col-xl-12">
                    <Form
                      formFields={formCoBuyerStructure}
                      {...formBuyersDefinition}
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div
            class="tab-pane fade"
            id="nav-contact"
            role="tabpanel"
            aria-labelledby="nav-contact-tab"
          >
            <div className="card card-default" bis_skin_checked="1">
              <div className="card-header" bis_skin_checked="1">
                {/* <h2></h2> */}
              </div>
              <div className="card-body" bis_skin_checked="1">
                <div
                  className="row"
                  bis_skin_checked="1"
                  style={{
                    border: "1px solid #eee",
                    paddingTop: "20px",
                    paddingBottom: "20px",
                  }}
                >
                  <div className="col-xl-12">
                    <Form
                      formFields={formVehicleStructure}
                      {...formVehicleDefinition}
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div
            class="tab-pane fade"
            id="nav-taxes"
            role="tabpanel"
            aria-labelledby="nav-taxes-tab"
          >
            <div className="card card-default" bis_skin_checked="1">
              <div className="card-header" bis_skin_checked="1">
                {/* <h2></h2> */}
              </div>
              <div className="card-body" bis_skin_checked="1">
                <div
                  className="row"
                  bis_skin_checked="1"
                  style={{
                    border: "1px solid #eee",
                    paddingTop: "20px",
                    paddingBottom: "20px",
                  }}
                >
                  <div className="col-xl-12">
                    <Form
                      formFields={formTaxesStructure}
                      {...formTaxDefinition}
                      dbFormData={dbTaxData}
                      optionTitle={taxoptionTitle}
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div
            class="tab-pane fade"
            id="nav-Fees"
            role="tabpanel"
            aria-labelledby="nav-Fees-tab"
          >
            <div className="card card-default" bis_skin_checked="1">
              <div className="card-header" bis_skin_checked="1">
                {/* <h2></h2> */}
              </div>
              <div className="card-body" bis_skin_checked="1">
                <div
                  className="row"
                  bis_skin_checked="1"
                  style={{
                    border: "1px solid #eee",
                    paddingTop: "20px",
                    paddingBottom: "20px",
                  }}
                >
                  <div className="col-xl-12">
                    <Form
                      formFields={formFeesStructure}
                      {...formFeeDefinition}
                      dbFormData={dbFeesData}
                      optionTitle={feesoptionTitle}
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div
            class="tab-pane fade"
            id="nav-lender"
            role="tabpanel"
            aria-labelledby="nav-lender-tab"
          >
            <div className="card card-default" bis_skin_checked="1">
              <div className="card-header" bis_skin_checked="1">
                {/* <h2></h2> */}
              </div>
              <div className="card-body" bis_skin_checked="1">
                <div
                  className="row"
                  bis_skin_checked="1"
                  style={{
                    border: "1px solid #eee",
                    paddingTop: "20px",
                    paddingBottom: "20px",
                  }}
                >
                  <div className="col-xl-12">
                    <Form
                      formFields={formLenderStructure}
                      {...formLendersDefinition}
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div
            class="tab-pane fade"
            id="nav-trade"
            role="tabpanel"
            aria-labelledby="nav-trade-tab"
          >
            <div className="card card-default" bis_skin_checked="1">
              <div className="card-header" bis_skin_checked="1">
                {/* <h2></h2> */}
              </div>
              <div className="card-body" bis_skin_checked="1">
                <div
                  className="row"
                  bis_skin_checked="1"
                  style={{
                    border: "1px solid #eee",
                    paddingTop: "20px",
                    paddingBottom: "20px",
                  }}
                >
                  <div className="col-xl-12">
                    <Form
                      formFields={formTradeStructure}
                      {...formTradeInsDefinition}
                      dbFormData={dbTradeinData}
                      optionTitle={feesTradeinTitle}
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </MainWrapper>
    </>
  );
};

export default EditNewDeals;
