import React, { useState, useRef, useEffect } from "react";
import { getCookie, destroyCookie } from "../../utils";
import { useNavigate } from "react-router-dom";
import Peer from "peerjs";
import { Modal, Button } from "react-bootstrap";
// import "bootstrap/dist/css/bootstrap.min.css";
// import "bootstrap/dist/js/bootstrap.bundle.min";
import axios from "axios";
import Cookies from "js-cookie";

const Header = ({ title }) => {
  const navigate = useNavigate();
  const loginedUser = JSON.parse(getCookie("loginData"));

  if (!loginedUser) {
    navigate("/login"); // Redirect to login page if the cookie is not found
  }

  const [peerId, setPeerId] = useState("");
  const [remotePeerId, setRemotePeerId] = useState("");
  const [peer, setPeer] = useState(null);
  const [call, setCall] = useState(null);
  const [stream, setStream] = useState(null);
  const [incomingCall, setIncomingCall] = useState(null);
  const [isCallPending, setIsCallPending] = useState(false);
  const [isVideoMuted, setIsVideoMuted] = useState(false);
  const [isAudioMuted, setIsAudioMuted] = useState(false);
  const [showModal, setShowModal] = useState(false);
  const localVideoRef = useRef(null);
  const remoteVideoRef = useRef(null);

  const handleLogout = (e) => {
    destroyCookie("loginData");
    navigate("/login");
  };

  useEffect(() => {
    const newPeer = new Peer();

    newPeer.on("open", (id) => {
      setPeerId(id); // Set the Peer ID state
      storeCustomerUrl(id); // Now, pass the Peer ID directly after it's set
    });

    newPeer.on("error", (err) => {
      console.error("PeerJS error:", err);
    });

    newPeer.on("call", (incoming) => {
      var a = new Audio(
        "https://www.mobilesringtones.com/static/p/ringtones/2018/03/28/18145/18145.mp3?title=18145_download_basic_bell_ringtone_mp3_sms_ringtones.mp3"
      );
      a.play();
      setIncomingCall(incoming);
      setIsCallPending(true);
      setShowModal(true); // Open the modal when a call is received

      incoming.on("stream", (remoteStream) => {
        remoteVideoRef.current.srcObject = remoteStream;
      });
    });

    setPeer(newPeer);

    return () => {
      newPeer.destroy();
    };
  }, []);

  // Pass peerId directly from the PeerJS event
  const storeCustomerUrl = async (peerId) => {
    const loginedUser = JSON.parse(getCookie("loginData"));
    const customerId = loginedUser.data.id;
    const isAdmin = localStorage.getItem("controller");
    const url = window.location.href.split("?")[0];

    const cookieValue = Cookies.get("loginData");
    const parsedCookie = JSON.parse(cookieValue);
    const token = parsedCookie?.data?.token;

    if (customerId && isAdmin !== "admin") {
      try {
        const response = await axios.post(
          `https://api.menuverse.ai/users/update/${customerId}`,
          { url: url, peerId: peerId }, // Send the correct peerId
          {
            headers: {
              "Content-Type": "application/json",
              authorization: `Bearer ${token}`,
            },
          }
        );
        console.log("Response:", response.data);
      } catch (error) {
        console.error("Error storing customer URL:", error);
      }
    } else {
      console.log("API call skipped. User is admin or customer ID not found.");
    }
  };

  useEffect(() => {
    if (peer) {
      const dataConnection = peer.connect(remotePeerId);

      dataConnection.on("open", () => {
        // Send control commands, e.g., mouse move
        const sendControlCommand = (type, data) => {
          dataConnection.send({ type, data });
        };

        // Example: sending mouse movement
        document.addEventListener("mousemove", (event) => {
          sendControlCommand("mouseMove", {
            x: event.clientX,
            y: event.clientY,
          });
        });
      });
    }
  }, [peer, remotePeerId]);

  console.log(peerId);

  const startCall = async () => {
    if (!remotePeerId || !peer) return;

    try {
      const mediaStream = await navigator.mediaDevices.getUserMedia({
        video: true,
        audio: true,
      });
      setStream(mediaStream);
      localVideoRef.current.srcObject = mediaStream;

      const outgoingCall = peer.call(remotePeerId, mediaStream);

      outgoingCall.on("stream", (remoteStream) => {
        remoteVideoRef.current.srcObject = remoteStream;
      });

      outgoingCall.on("close", () => {
        endCall();
      });

      setCall(outgoingCall);
    } catch (error) {
      console.error("Error accessing media devices.", error);
      alert(
        "Error accessing media devices. Please ensure your camera and microphone are properly connected and try again."
      );
    }
  };

  const acceptCall = () => {
    if (!incomingCall || !peer) return;

    navigator.mediaDevices
      .getUserMedia({ video: true, audio: true })
      .then((mediaStream) => {
        setStream(mediaStream);
        localVideoRef.current.srcObject = mediaStream;
        incomingCall.answer(mediaStream);

        incomingCall.on("stream", (remoteStream) => {
          remoteVideoRef.current.srcObject = remoteStream;
        });

        incomingCall.on("close", () => {
          endCall();
        });

        setCall(incomingCall);
        setIncomingCall(null);
        setIsCallPending(false);
      });
  };

  const declineCall = () => {
    if (incomingCall) {
      incomingCall.close();
      setIncomingCall(null);
      setIsCallPending(false);
    }
  };

  const endCall = () => {
    if (call) {
      call.close();
    }
    if (stream) {
      stream.getTracks().forEach((track) => track.stop());
    }
    setCall(null);
    setStream(null);
    localVideoRef.current.srcObject = null;
    remoteVideoRef.current.srcObject = null;
    setShowModal(false); // Close the modal when the call ends
  };

  const toggleVideo = () => {
    if (stream) {
      const videoTrack = stream.getVideoTracks()[0];
      if (videoTrack) {
        videoTrack.enabled = !videoTrack.enabled;
        setIsVideoMuted(!videoTrack.enabled);
      }
    }
  };

  const toggleAudio = () => {
    if (stream) {
      const audioTrack = stream.getAudioTracks()[0];
      if (audioTrack) {
        audioTrack.enabled = !audioTrack.enabled;
        setIsAudioMuted(!audioTrack.enabled);
      }
    }
  };

  return (
    <header className="main-header" id="header">
      <nav className="navbar navbar-expand-lg navbar-light" id="navbar">
        <button id="sidebar-toggler" className="sidebar-toggle">
          <span className="sr-only">Toggle navigation</span>
        </button>

        <span className="page-title">{title || "dashboard"}</span>

        <div className="navbar-right">
          <div className="search-form">
            <form action="index.html" method="get">
              <div
                className="input-group input-group-sm"
                id="input-group-search"
              >
                {/* <input
                  type="text"
                  autoComplete="off"
                  name="query"
                  id="search-input"
                  className="form-control"
                  placeholder="Search..."
                />
                <div className="input-group-append">
                  <button className="btn" type="button">
                    /
                  </button>
                </div> */}
              </div>
            </form>
            {/* <ul className="dropdown-menu dropdown-menu-search">
              <li className="nav-item">
                <a className="nav-link" href="index.html">
                  Morbi leo risus
                </a>
              </li>
              <li className="nav-item">
                <a className="nav-link" href="index.html">
                  Dapibus ac facilisis in
                </a>
              </li>
              <li className="nav-item">
                <a className="nav-link" href="index.html">
                  Porta ac consectetur ac
                </a>
              </li>
              <li className="nav-item">
                <a className="nav-link" href="index.html">
                  Vestibulum at eros
                </a>
              </li>
            </ul> */}
          </div>

          <ul className="nav navbar-nav">
            <li className="custom-dropdown">
              {/* <a
                className="offcanvas-toggler active custom-dropdown-toggler"
                data-offcanvas="contact-off"
                href="#"
              >
                <i className="mdi mdi-contacts icon"></i>
              </a> */}

              <p>Your ID: {peerId}</p>
            </li>
            <li className="custom-dropdown">
              <button className="notify-toggler custom-dropdown-toggler">
                <i className="mdi mdi-bell-outline icon"></i>
                <span className="badge badge-xs rounded-circle">21</span>
              </button>
              <div className="dropdown-notify">
                <header>
                  <div
                    className="nav nav-underline"
                    id="nav-tab"
                    role="tablist"
                  >
                    {/* <a
                      className="nav-item nav-link active"
                      id="all-tabs"
                      data-toggle="tab"
                      href="#all"
                      role="tab"
                      aria-controls="nav-home"
                      aria-selected="true"
                    >
                      All (5)
                    </a> */}
                    {/* <a
                      className="nav-item nav-link"
                      id="message-tab"
                      data-toggle="tab"
                      href="#message"
                      role="tab"
                      aria-controls="nav-profile"
                      aria-selected="false"
                    >
                      Msgs (4)
                    </a> */}
                    {/* <a
                      className="nav-item nav-link"
                      id="other-tab"
                      data-toggle="tab"
                      href="#other"
                      role="tab"
                      aria-controls="nav-contact"
                      aria-selected="false"
                    >
                      Others (3)
                    </a> */}
                  </div>
                </header>

                <div className="" data-simplebar style={{ height: "325px" }}>
                  <div className="tab-content" id="myTabContent">
                    <div
                      className="tab-pane fade show active"
                      id="all"
                      role="tabpanel"
                      aria-labelledby="all-tabs"
                    >
                      <div className="media media-sm bg-warning-10 p-4 mb-0">
                        <div className="media-sm-wrapper">
                          <a href="user-profile.html">
                            <img
                              src="/images/user/user-sm-02.jpg"
                              alt="User Image"
                            />
                          </a>
                        </div>
                        <div className="media-body">
                          <a href="user-profile.html">
                            <span className="title mb-0"></span>
                            <span className="discribe">
                              Extremity sweetness difficult behaviour he of. On
                              disposal of as landlord horrible. Afraid at highly
                              months do things on at.
                            </span>
                            <span className="time">
                              <time>Just now</time>...
                            </span>
                          </a>
                        </div>
                      </div>

                      <div className="media media-sm p-4 bg-light mb-0">
                        <div className="media-sm-wrapper bg-primary">
                          <a href="user-profile.html">
                            <i className="mdi mdi-calendar-check-outline"></i>
                          </a>
                        </div>
                        <div className="media-body">
                          <a href="user-profile.html">
                            <span className="title mb-0">New event added</span>
                            <span className="discribe">
                              1/3/2014 (1pm - 2pm)
                            </span>
                            <span className="time">
                              <time>10 min ago...</time>...
                            </span>
                          </a>
                        </div>
                      </div>

                      <div className="media media-sm p-4 mb-0">
                        <div className="media-sm-wrapper">
                          <a href="user-profile.html">
                            <img
                              src="/images/user/user-sm-03.jpg"
                              alt="User Image"
                            />
                          </a>
                        </div>
                        <div className="media-body">
                          <a href="user-profile.html">
                            <span className="title mb-0">Sagge Hudson</span>
                            <span className="discribe">
                              On disposal of as landlord Afraid at highly months
                              do things on at.
                            </span>
                            <span className="time">
                              <time>1 hrs ago</time>...
                            </span>
                          </a>
                        </div>
                      </div>

                      <div className="media media-sm p-4 mb-0">
                        <div className="media-sm-wrapper bg-info-dark">
                          <a href="user-profile.html">
                            <i className="mdi mdi-account-multiple-check"></i>
                          </a>
                        </div>
                        <div className="media-body">
                          <a href="user-profile.html">
                            <span className="title mb-0">Add request</span>
                            <span className="discribe">
                              Add Dany Jones as your contact.
                            </span>
                            <div className="buttons">
                              <a
                                href="#"
                                className="btn btn-sm btn-success shadow-none text-white"
                              >
                                accept
                              </a>
                              <a href="#" className="btn btn-sm shadow-none">
                                delete
                              </a>
                            </div>
                            <span className="time">
                              <time>6 hrs ago</time>...
                            </span>
                          </a>
                        </div>
                      </div>

                      <div className="media media-sm p-4 mb-0">
                        <div className="media-sm-wrapper bg-info">
                          <a href="user-profile.html">
                            <i className="mdi mdi-playlist-check"></i>
                          </a>
                        </div>
                        <div className="media-body">
                          <a href="user-profile.html">
                            <span className="title mb-0">Task complete</span>
                            <span className="discribe">
                              Afraid at highly months do things on at.
                            </span>
                            <span className="time">
                              <time>1 hrs ago</time>...
                            </span>
                          </a>
                        </div>
                      </div>
                    </div>

                    <div
                      className="tab-pane fade"
                      id="message"
                      role="tabpanel"
                      aria-labelledby="message-tab"
                    >
                      <div className="media media-sm p-4 mb-0">
                        <div className="media-sm-wrapper">
                          <a href="user-profile.html">
                            <img
                              src="/images/user/user-sm-01.jpg"
                              alt="User Image"
                            />
                          </a>
                        </div>
                        <div className="media-body">
                          <a href="user-profile.html">
                            <span className="title mb-0">Selena Wagner</span>
                            <span className="discribe">
                              Lorem ipsum dolor sit amet, consectetur
                              adipisicing elit.
                            </span>
                            <span className="time">
                              <time>15 min ago</time>...
                            </span>
                          </a>
                        </div>
                      </div>

                      <div className="media media-sm p-4 mb-0">
                        <div className="media-sm-wrapper">
                          <a href="user-profile.html">
                            <img
                              src="/images/user/user-sm-03.jpg"
                              alt="User Image"
                            />
                          </a>
                        </div>
                        <div className="media-body">
                          <a href="user-profile.html">
                            <span className="title mb-0">Sagge Hudson</span>
                            <span className="discribe">
                              On disposal of as landlord Afraid at highly months
                              do things on at.
                            </span>
                            <span className="time">
                              <time>1 hrs ago</time>...
                            </span>
                          </a>
                        </div>
                      </div>

                      <div className="media media-sm bg-warning-10 p-4 mb-0">
                        <div className="media-sm-wrapper">
                          <a href="user-profile.html">
                            <img
                              src="/images/user/user-sm-02.jpg"
                              alt="User Image"
                            />
                          </a>
                        </div>
                        <div className="media-body">
                          <a href="user-profile.html">
                            <span className="title mb-0">Johns Doe</span>
                            <span className="discribe">
                              Extremity sweetness difficult behaviour he of. On
                              disposal of as landlord horrible. Afraid at highly
                              months do things on at.
                            </span>
                            <span className="time">
                              <time>Just now</time>...
                            </span>
                          </a>
                        </div>
                      </div>

                      <div className="media media-sm p-4 mb-0">
                        <div className="media-sm-wrapper">
                          <a href="user-profile.html">
                            <img
                              src="/images/user/user-sm-04.jpg"
                              alt="User Image"
                            />
                          </a>
                        </div>
                        <div className="media-body">
                          <a href="user-profile.html">
                            <span className="title mb-0">Albrecht Straub</span>
                            <span className="discribe">
                              {" "}
                              Beatae quia natus assumenda laboriosam, nisi
                              perferendis aliquid consectetur expedita non
                              tenetur.
                            </span>
                            <span className="time">
                              <time>Just now</time>...
                            </span>
                          </a>
                        </div>
                      </div>
                    </div>
                    <div
                      className="tab-pane fade"
                      id="other"
                      role="tabpanel"
                      aria-labelledby="contact-tab"
                    >
                      <div className="media media-sm p-4 bg-light mb-0">
                        <div className="media-sm-wrapper bg-primary">
                          <a href="user-profile.html">
                            <i className="mdi mdi-calendar-check-outline"></i>
                          </a>
                        </div>
                        <div className="media-body">
                          <a href="user-profile.html">
                            <span className="title mb-0">New event added</span>
                            <span className="discribe">
                              1/3/2014 (1pm - 2pm)
                            </span>
                            <span className="time">
                              <time>10 min ago...</time>...
                            </span>
                          </a>
                        </div>
                      </div>

                      <div className="media media-sm p-4 mb-0">
                        <div className="media-sm-wrapper bg-info-dark">
                          <a href="user-profile.html">
                            <i className="mdi mdi-account-multiple-check"></i>
                          </a>
                        </div>
                        <div className="media-body">
                          <a href="user-profile.html">
                            <span className="title mb-0">Add request</span>
                            <span className="discribe">
                              Add Dany Jones as your contact.
                            </span>
                            <div className="buttons">
                              <a
                                href="#"
                                className="btn btn-sm btn-success shadow-none text-white"
                              >
                                accept
                              </a>
                              <a href="#" className="btn btn-sm shadow-none">
                                delete
                              </a>
                            </div>
                            <span className="time">
                              <time>6 hrs ago</time>...
                            </span>
                          </a>
                        </div>
                      </div>

                      <div className="media media-sm p-4 mb-0">
                        <div className="media-sm-wrapper bg-info">
                          <a href="user-profile.html">
                            <i className="mdi mdi-playlist-check"></i>
                          </a>
                        </div>
                        <div className="media-body">
                          <a href="user-profile.html">
                            <span className="title mb-0">Task complete</span>
                            <span className="discribe">
                              Afraid at highly months do things on at.
                            </span>
                            <span className="time">
                              <time>1 hrs ago</time>...
                            </span>
                          </a>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>

                <footer className="border-top dropdown-notify-footer">
                  <div className="d-flex justify-content-between align-items-center py-2 px-4">
                    <span>Last updated 3 min ago</span>
                    <a
                      id="refress-button"
                      href="javascript:"
                      className="btn mdi mdi-cached btn-refress"
                    ></a>
                  </div>
                </footer>
              </div>
            </li>
            <li className="dropdown user-menu">
              <button
                className="dropdown-toggle nav-link"
                data-toggle="dropdown"
              >
                <img
                  src="/images/user/user-xs-01.jpg"
                  className="user-image rounded-circle"
                  alt="User"
                />
                <span className="d-none d-lg-inline-block">
                  {loginedUser ? loginedUser.data.name : ""}
                </span>
              </button>
              <ul className="dropdown-menu dropdown-menu-right">
                <li>
                  <a className="dropdown-link-item" href="user-profile.html">
                    <i className="mdi mdi-account-outline"></i>
                    <span className="nav-text">My Profile</span>
                  </a>
                </li>
                {/* <li>
                  <a className="dropdown-link-item" href="email-inbox.html">
                    <i className="mdi mdi-email-outline"></i>
                    <span className="nav-text">Message</span>
                    <span className="badge badge-pill badge-primary">24</span>
                  </a>
                </li> */}
                {/* <li>
                  <a className="dropdown-link-item" href="user-activities.html">
                    <i className="mdi mdi-diamond-stone"></i>
                    <span className="nav-text">Activitise</span>
                  </a>
                </li> */}
                <li>
                  <a
                    className="dropdown-link-item"
                    href="user-account-settings.html"
                  >
                    <i className="mdi mdi-settings"></i>
                    <span className="nav-text">Account Setting</span>
                  </a>
                </li>

                <li className="dropdown-footer">
                  <a
                    className="dropdown-link-item"
                    href="#"
                    onClick={handleLogout}
                  >
                    {" "}
                    <i className="mdi mdi-logout"></i> Log Out{" "}
                  </a>
                </li>
              </ul>
            </li>
          </ul>

          <Button
            variant="primary"
            className="d-none"
            onClick={() => setShowModal(true)}
          >
            Start Video Call
          </Button>
        </div>

        {/* Video Call Modal */}
        <Modal
          show={showModal}
          onHide={() => setShowModal(false)}
          size="xl"
          centered
          className="remote-modal"
        >
          <Modal.Header closeButton>
            <Modal.Title>Video Call</Modal.Title>
            {/* <p>Your ID: {peerId}</p> */}
          </Modal.Header>
          <Modal.Body>
            <div className="video-call-container mod">
              <div className="video-wrapper">
                <video
                  ref={localVideoRef}
                  autoPlay
                  playsInline
                  muted
                  className="local-video"
                />
                <video
                  ref={remoteVideoRef}
                  autoPlay
                  playsInline
                  className="remote-video"
                />
              </div>

              {isCallPending && incomingCall && (
                <div className="call-prompt">
                  <h3>Incoming Call from {incomingCall.peer}</h3>
                  <Button
                    onClick={acceptCall}
                    className="accept-button"
                    variant="success"
                  >
                    Accept
                  </Button>
                  <Button
                    onClick={declineCall}
                    className="decline-button"
                    variant="danger"
                  >
                    Decline
                  </Button>
                </div>
              )}

              <div className="controls">
                {!call && (
                  <>
                    {/* <input
                      type="text"
                      value={remotePeerId}
                      onChange={(e) => setRemotePeerId(e.target.value)}
                      placeholder="Enter Remote Peer ID"
                      className="peer-id-input"
                    />
                    <Button onClick={startCall} className="control-button" variant="primary">Start Call</Button> */}
                  </>
                )}

                {call && (
                  <>
                    <div className="d-flex abmu">
                      <Button
                        onClick={endCall}
                        className="end-call-button"
                        variant="danger"
                      >
                        <i class="fa-solid fa-phone-flip end-call-icon"></i>
                      </Button>
                      <Button
                        onClick={toggleVideo}
                        className="control-button"
                        variant="secondar"
                      >
                        {isVideoMuted ? (
                          <i class="fa-solid fa-video-slash"></i>
                        ) : (
                          <i class="fa-solid fa-video"></i>
                        )}
                      </Button>
                      <Button
                        onClick={toggleAudio}
                        className="control-button"
                        variant="secondar"
                      >
                        {isAudioMuted ? (
                          <i class="fa-solid fa-microphone-slash"></i>
                        ) : (
                          <i class="fa-solid fa-microphone"></i>
                        )}
                      </Button>
                    </div>
                  </>
                )}
              </div>
            </div>
          </Modal.Body>
        </Modal>
      </nav>
    </header>
  );
};

export default Header;
