import React, { useEffect } from "react";
import MainWrapper from "../Main/MainWrapper";
import { useState } from "react";
import { useNavigate } from "react-router-dom";
import Form from "../Common/Form";
import { getCookie } from "../../utils";

const AddServiceCategory = () => {
  const loginedUser = JSON.parse(getCookie("loginData"));

  const optionTitle = "Warranty Company Seletion Criteria";

  const navigate = useNavigate();

  const [warrantyCompanies, setWarrantyCompanies] = useState([]);
  const [coupons, setCoupons] = useState([]);

  useEffect(() => {
    const fetchWarrantyComapnies = async () => {
      const response = await fetch("https://api.menverse.ai/vendor/show", {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          authorization: "Bearer " + loginedUser.data.token,
        },
      });

      if (response.status === 201 || response.status === 200) {
      } else if (response.status === 500) {
        const result = await response.json();
        window.toastr.error(result.message);
      } else if (response.status === 401 || response.status === 403) {
        navigate("/login");
      }

      const responseData = await response.json();

      setWarrantyCompanies(responseData.data);

      console.log("responseData", responseData);
    };

    fetchWarrantyComapnies();

    const fetchCoupons = async () => {
      const response = await fetch("https://api.menverse.ai/coupons/show", {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          authorization: "Bearer " + loginedUser.data.token,
        },
      });

      if (response.status === 201 || response.status === 200) {
      } else if (response.status === 500) {
        const result = await response.json();
        window.toastr.error(result.message);
      } else if (response.status === 401 || response.status === 403) {
        navigate("/login");
      }

      const responseData = await response.json();

      setCoupons(responseData.data);
    };

    fetchCoupons();
  }, []);

  const years = [
    { name: "Life Time", id: 100 },
    { name: "Same as Loan", id: 0 },
    { name: "1 Year", id: 1 },
    { name: "2 Years", id: 2 },
    { name: "3 Years", id: 3 },
    { name: "4 Years", id: 4 },
    { name: "5 Years", id: 5 },
    { name: "6 Years", id: 6 },
    { name: "7 Years", id: 7 },
    { name: "8 Years", id: 8 },
    { name: "9 Years", id: 9 },
    { name: "10 Years", id: 10 },
    { name: "11 Years", id: 11 },
    { name: "12 Years", id: 12 },
    { name: "13 Years", id: 13 },
    { name: "14 Years", id: 14 },
    { name: "15 Years", id: 15 },
  ];

  const formFields = [
    {
      fieldName: "name",
      fieldType: "text",
      fieldMask: "",
      fieldMaxLength: "100",
      fieldRequired: "true",
      fieldDefaultValue: "",
      fieldLabel: "Service Name",
    },
    {
      fieldName: "coupon_id",
      fieldType: "select",
      fieldMask: "",
      fieldMaxLength: "",
      // fieldRequired: "true",
      fieldDefaultValue: "",
      fieldValue: "",
      fieldOptions: coupons,
      dbType: "array",
      select_multiple: "true",
      fieldLabel: "Attach Discount Coupon",
    },

    {
      fieldName: "profit_type",
      fieldType: "select",
      fieldMask: "",
      fieldMaxLength: "",
      fieldRequired: "true",
      fieldDefaultValue: "",
      fieldValue: "",
      fieldOptions: [
        { id: "Percentage", name: "Percentage" },
        { id: "Flat", name: "Flat" },
      ],
      dbType: "string",
      fieldLabel: "Profit type if any",
    },
    {
      fieldName: "profit",
      fieldType: "number",
      fieldMask: "",
      fieldMaxLength: "",
      fieldRequired: "true",
      fieldDefaultValue: "",
      fieldLabel: "How much profit you want on this product?",
    },

    {
      fieldName: "car_age",
      fieldType: "number",
      fieldMask: "",
      fieldMaxLength: "",
      fieldRequired: "true",
      fieldDefaultValue: "",
      fieldLabel: "Max Car Age in months <em>(Optional)</em>",
    },
    {
      fieldName: "default_detuctible",
      fieldType: "number",
      fieldMask: "",
      fieldMaxLength: "",
      fieldRequired: "true",
      fieldDefaultValue: "",
      fieldLabel: "Default deductible in $ If Any ",
    },
    {
      fieldName: "is_default",
      fieldType: "radio",
      fieldMask: "",
      fieldMaxLength: "100",
      fieldRequired: "true",
      fieldDefaultValue: "",
      fieldValue: "yes",
      fieldLabel: "Want to set default number of years?",
    },
    {
      fieldName: "is_default",
      fieldType: "radio",
      fieldMask: "",
      fieldMaxLength: "100",
      fieldRequired: "true",
      fieldDefaultValue: "",
      fieldValue: "no",
      fieldLabel: "Want to set default number of years?",
    },
    {
      fieldName: "default_years",
      fieldType: "select",
      fieldMask: "",
      fieldMaxLength: "",
      fieldRequired: "true",
      fieldDefaultValue: "",
      fieldValue: "",
      fieldOptions: years,
      dbType: "string",
      fieldLabel: "Default Timeline",
    },

    {
      fieldName: "is_default_editable",
      fieldType: "radio",
      fieldMask: "",
      fieldMaxLength: "100",
      fieldRequired: "true",
      fieldDefaultValue: "",
      fieldValue: "yes",
      fieldLabel: "Is Default Editable",
    },
    {
      fieldName: "is_default_editable",
      fieldType: "radio",
      fieldMask: "",
      fieldMaxLength: "100",
      fieldRequired: "true",
      fieldDefaultValue: "",
      fieldValue: "no",
      fieldLabel: "Is Default Editable",
    },
    {
      fieldName: "default_years_differ_for_new_and_used",
      fieldType: "radio",
      fieldMask: "",
      fieldMaxLength: "100",
      fieldRequired: "true",
      fieldDefaultValue: "",
      fieldValue: "yes",
      fieldLabel: "Default years will be different for new and old cars?",
    },
    {
      fieldName: "default_years_differ_for_new_and_used",
      fieldType: "radio",
      fieldMask: "",
      fieldMaxLength: "100",
      fieldRequired: "true",
      fieldDefaultValue: "",
      fieldValue: "no",
      fieldLabel: "",
    },

    {
      fieldName: "new_default_years",
      fieldType: "select",
      fieldMask: "",
      fieldMaxLength: "",
      fieldRequired: "true",
      fieldDefaultValue: "",
      fieldValue: "",
      fieldOptions: years,
      dbType: "string",
      fieldLabel: "Set Default Timeline For New Car",
    },

    {
      fieldName: "used_default_years",
      fieldType: "select",
      fieldMask: "",
      fieldMaxLength: "",
      fieldRequired: "true",
      fieldDefaultValue: "",
      fieldValue: "",
      fieldOptions: years,
      dbType: "string",
      fieldLabel: "Set Default Timeline For Used Car",
    },
    {
      fieldName: "warranty_company_differ_for_new_and_used",
      fieldType: "radio",
      fieldMask: "",
      fieldMaxLength: "100",
      fieldRequired: "true",
      fieldDefaultValue: "",
      fieldValue: "yes",
      fieldLabel: "Warranty Company will be different for new and used cars?",
    },
    {
      fieldName: "warranty_company_differ_for_new_and_used",
      fieldType: "radio",
      fieldMask: "",
      fieldMaxLength: "100",
      fieldRequired: "true",
      fieldDefaultValue: "",
      fieldValue: "no",
      fieldLabel: "",
    },
    {
      fieldName: "new_warranty_company",
      fieldType: "select",
      fieldMask: "",
      fieldMaxLength: "",
      fieldRequired: "true",
      fieldDefaultValue: "",
      fieldValue: "",
      fieldOptions: warrantyCompanies,
      dbType: "integer",
      fieldLabel: "Set New Car Warranty Company",
    },
    {
      fieldName: "used_warranty_company",
      fieldType: "select",
      fieldMask: "",
      fieldMaxLength: "",
      fieldRequired: "true",
      fieldDefaultValue: "",
      fieldValue: "",
      fieldOptions: warrantyCompanies,
      dbType: "integer",
      fieldLabel: "Set Used Car Warranty Company",
    },
    {
      fieldName: "ranges",
      fieldType: "multiples",
      fieldMask: "",
      fieldMaxLength: "100",
      fieldRequired: "true",
      fieldDefaultValue: "",
      multiples: [
        {
          fieldName: "age_from",
          fieldType: "text",
          fieldMask: "",
          fieldMaxLength: "100",
          fieldRequired: "true",
          fieldDefaultValue: "",
          fieldLabel: "Minimum car age",
        },
        {
          fieldName: "age_to",
          fieldType: "text",
          fieldMask: "",
          fieldMaxLength: "100",
          fieldRequired: "false",
          fieldDefaultValue: "",
          fieldLabel: "Maximum car age",
        },
        {
          fieldName: "miles_from",
          fieldType: "text",
          fieldMask: "",
          fieldMaxLength: "100",
          fieldRequired: "true",
          fieldDefaultValue: "",
          fieldLabel: "Minimum Miles driven",
        },
        {
          fieldName: "miles_to",
          fieldType: "text",
          fieldMask: "",
          fieldMaxLength: "100",
          fieldRequired: "false",
          fieldDefaultValue: "",
          fieldLabel: "Maximum Miles driven",
        },
        {
          fieldName: "vendor_id",
          fieldType: "select",
          fieldMask: "",
          fieldMaxLength: "",
          fieldRequired: "true",
          fieldDefaultValue: "",
          fieldValue: "",
          fieldOptions: warrantyCompanies,
          dbType: "integer",
          fieldLabel: "Warranty Company",
        },
      ],
    },
  ];

  const formDefinition = {
    type: "add",
    URL: "https://api.menverse.ai/service_category/create",
    successRedirect: "/service-categories",
  };

  return (
    <>
      <MainWrapper title="Add Service Category">
        <div className="card card-default" bis_skin_checked="1">
          <div className="card-header" bis_skin_checked="1">
            {/* <h2></h2> */}
          </div>
          <div className="card-body" bis_skin_checked="1">
            <div
              className="row"
              bis_skin_checked="1"
              style={{
                border: "1px solid #eee",
                paddingTop: "20px",
                paddingBottom: "20px",
              }}
            >
              <div className="col-xl-12">
                <Form
                  formFields={formFields}
                  {...formDefinition}
                  addFormData={formDefinition}
                  optionTitle={optionTitle}
                />
              </div>
            </div>
          </div>
        </div>
      </MainWrapper>
    </>
  );
};

export default AddServiceCategory;
